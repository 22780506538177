import React from 'react';
import { Card } from '@rentacenter/racstrap';
import { Skeleton } from '@material-ui/lab';

import styles from './ReportsList.module.scss';
export const reportListSkeletonTestId = 'reportListSkeletonTestId';
export const numberOfCards = 10;

export const ReportListSkeleton = () => {
  return (
    <>
      {new Array(numberOfCards).fill(null).map((item, index) => (
        <Card
          className={styles.reportEntry}
          key={index}
          data-testid={reportListSkeletonTestId}
        >
          <Skeleton variant="text" height={30} animation="wave" />
          <Skeleton variant="text" animation="wave" />
          <Skeleton variant="text" animation="wave" />
        </Card>
      ))}
    </>
  );
};
