import React, { useContext, useState } from 'react';
import { Button, DatePicker, Checkbox, Select } from '@rentacenter/racstrap';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { format } from 'date-fns';

import styles from './ExceptionAuditTrail.module.scss';

import { StoreContext } from '../../../context/store/StoreProvider';
import { useDateRangeValidator } from '../../../utils/useRangeValidator';
import { Footer } from '../../layout/footer/Footer';
import { BackButton } from '../BackButton';
import { SIX_MONTHS_AGO } from '../constants';
import { useEmployeeOptions } from '../useEmployeeOptions';
import { GenerateReportType } from '../../../domain/Reports/Reports';
import { useGenerateReport } from '../useGenerateReport';

interface ExceptionAuditTrailForm {
  readonly startDate: Date;
  readonly endDate: Date;
  readonly empId: string;
  readonly Account_management: boolean;
  readonly Payments: boolean;
  readonly Cash_management: boolean;
  readonly Customer: boolean;
  readonly Inventory: boolean;
  readonly Agreement: boolean;
}

const ExceptionAuditTrailFormId = 'exceptionAuditTrailFormId';

export enum filterOptions {
  YES = 'Yes',
  NO = 'No'
}

export const ExceptionAuditTrail = () => {
  const { selectedStore } = useContext(StoreContext);
  const { pending, generateReport } = useGenerateReport();
  const { employeesOptions, employeesApiError } = useEmployeeOptions();

  const [accountManagement, setAccountManagement] = useState<boolean>(false);
  const [payments, setPayments] = useState<boolean>(false);
  const [cashManagement, setCashManagement] = useState<boolean>(false);
  const [customer, setCustomer] = useState<boolean>(false);
  const [inventory, setInventory] = useState<boolean>(false);
  const [agreement, setAgreement] = useState<boolean>(false);

  const { control, handleSubmit, errors, clearErrors, getValues } = useForm<
    ExceptionAuditTrailForm
  >({
    mode: 'all',
    defaultValues: {
      empId: 'All'
    }
  });

  const onSubmit = (data: ExceptionAuditTrailForm) => {
    if (!selectedStore) return;

    generateReport(selectedStore, {
      reportType: GenerateReportType.EXCEPTION_AUDIT_TRAIL,
      filters: {
        startDate: format(data?.startDate, 'MM/dd/yyyy'),
        endDate: format(data?.endDate, 'MM/dd/yyyy'),
        empId: (data?.empId !== 'All' && data?.empId) || undefined,
        accountManagement: accountManagement
          ? filterOptions.YES
          : filterOptions.NO,
        payment: payments ? filterOptions.YES : filterOptions.NO,
        cashManagement: cashManagement ? filterOptions.YES : filterOptions.NO,
        inventory: inventory ? filterOptions.YES : filterOptions.NO,
        customer: customer ? filterOptions.YES : filterOptions.NO,
        agreement: agreement ? filterOptions.YES : filterOptions.NO
      }
    });
  };

  const startDateFieldName = 'startDate';
  const endDateFieldName = 'endDate';

  const { startDate, endDate } = getValues();
  const { isAfterEnd, isBeforeStart } = useDateRangeValidator(
    startDate,
    endDate
  );

  return (
    <form id={ExceptionAuditTrailFormId} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.filterBy}>Filter By</div>
      <div className={styles.dateRange}>
        <div className={styles.datePicker}>
          <Controller
            control={control}
            name={startDateFieldName}
            defaultValue={null}
            rules={{
              required: 'This field is required',
              validate: isAfterEnd
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <DatePicker
                required
                label="Start Date"
                placeholder="Select a date"
                name={name}
                onChange={date => {
                  clearErrors('endDate');
                  onChange(date);
                }}
                value={value}
                invalid={invalid}
                datePickerProps={{
                  minDate: SIX_MONTHS_AGO,
                  disableFuture: true
                }}
                errorMessage={errors?.startDate?.message}
              />
            )}
          />
        </div>
        <div className={clsx(styles.datePicker, styles.right)}>
          <Controller
            control={control}
            name={endDateFieldName}
            defaultValue={null}
            rules={{
              required: 'This field is required',
              validate: isBeforeStart
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <DatePicker
                required
                placeholder="Select a date"
                label="End Date"
                name={name}
                onChange={e => {
                  clearErrors('startDate');
                  onChange(e);
                }}
                value={value}
                invalid={invalid}
                datePickerProps={{
                  minDate: SIX_MONTHS_AGO,
                  disableFuture: true
                }}
                errorMessage={errors?.endDate?.message}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.selectGroupNew}>
        <Controller
          control={control}
          name="empId"
          render={({ onChange, value, name }) => (
            <Select
              name={name}
              label="Employee Name"
              options={employeesOptions}
              onChange={onChange}
              value={value}
              size="large"
              className={clsx(styles.datePicker, styles.employee)}
              disabled={!!employeesApiError}
              errorMessage={employeesApiError}
            />
          )}
        />
      </div>
      <div className={styles.selectGroup1}>
        <div className={styles.textDiv}>
          <h3 className={styles.text}>Select one or more options</h3>
        </div>
        <Controller
          control={control}
          name="options"
          render={() => (
            <div>
              <div className={styles.checkbxDiv}>
                <Checkbox
                  labelText="Account Management"
                  size="medium"
                  data-testid="AcctMngmnt_chkbx"
                  onChange={e => {
                    setAccountManagement(e.target.checked);
                  }}
                />
              </div>

              <div className={styles.checkbxDiv}>
                <Checkbox
                  labelText="Payments"
                  data-testid="Payments_chkbx"
                  size="medium"
                  onChange={e => {
                    setPayments(e.target.checked);
                  }}
                />
              </div>
              <div className={styles.checkbxDiv}>
                <Checkbox
                  labelText="Cash Management"
                  size="medium"
                  data-testid="CashMngmnt_chkbx"
                  onChange={e => {
                    setCashManagement(e.target.checked);
                  }}
                />
              </div>
              <div className={styles.checkbxDiv}>
                <Checkbox
                  labelText="Inventory"
                  size="medium"
                  data-testid="Inventory_chkbx"
                  onChange={e => {
                    setInventory(e.target.checked);
                  }}
                />
              </div>
              <div className={styles.checkbxDiv}>
                <Checkbox
                  labelText="Customer"
                  data-testid="Customer_chkbx"
                  size="medium"
                  onChange={e => {
                    setCustomer(e.target.checked);
                  }}
                />
              </div>
              <div className={styles.checkbxDiv}>
                <Checkbox
                  labelText="Agreement"
                  data-testid="Agreement_chkbx"
                  size="medium"
                  onChange={e => {
                    setAgreement(e.target.checked);
                  }}
                />
              </div>
            </div>
          )}
        />
      </div>
      <Footer>
        <BackButton disabled={pending} />
        <Button type="submit" disabled={pending}>
          Generate Report
        </Button>
      </Footer>
    </form>
  );
};
