import { Dispatch } from 'react';

import {
  getUserAction,
  getUserSuccessAction,
  getUserErrorAction,
  UserAction
} from './user-actions';
import { User } from '../../domain/User/User';
import { getCurrentUser } from '../../api/api';

export interface UserThunks {
  getCurrentUser(): Promise<void>;
}

export const getCurrentUserThunk = (
  dispatch: Dispatch<UserAction>
) => async () => {
  try {
    dispatch(getUserAction());

    const user: User = await getCurrentUser();

    dispatch(getUserSuccessAction(user));
  } catch (error) {
    dispatch(getUserErrorAction(error));
  }
};
