import React, { useContext, useEffect, useState } from 'react';
import { Card } from '@rentacenter/racstrap';
import { Link } from 'react-router-dom';

import styles from './ReportsList.module.scss';

import { getReportsForStore } from '../../api/api';
import { StoreContext } from '../../context/store/StoreProvider';
import { getReportURLForType } from '../../domain/Reports/AvailableReports';
import { ReportServerItem } from '../../domain/Reports/Reports';
import { ApiError } from '../common/ApiError/ApiError';
import { ReportListSkeleton } from './ReportsListSkeleton';

export const reportEntryTestId = 'reportEntryTestId';

export interface CardLinkProps {
  reportURL: string;
  report: ReportServerItem;
}

export const ReportsList = () => {
  const { selectedStore } = useContext(StoreContext);
  const [reports, setReports] = useState<ReportServerItem[]>([]);
  const [loadingReports, setLoadingReports] = useState(false);
  const [hasApiError, setHasApiError] = useState(false);

  useEffect(() => {
    if (!selectedStore) return;
    setLoadingReports(true);
    setHasApiError(false);
    getReportsForStore(selectedStore)
      .then(reports => {
        setReports(reports);
      })
      .catch(() => setHasApiError(true))
      .finally(() => setLoadingReports(false));
  }, [selectedStore]);

  const CardLink = ({ reportURL, report }: CardLinkProps) => (
    <Link to={reportURL} className={styles.reportLink}>
      <Card className={styles.reportEntry} data-testid={reportEntryTestId}>
        <div className={styles.reportName}>{report.menuItemValue}</div>
        <div className={styles.reportDescription}>{report.displayValue}</div>
      </Card>
    </Link>
  );

  return (
    <div className={styles.reportListContainer}>
      <h1 className={styles.pageTitle}>Select a Report Type</h1>
      <div className={styles.reportsList}>
        {loadingReports ? (
          <ReportListSkeleton />
        ) : hasApiError ? (
          <ApiError className={styles.apiError} />
        ) : (
          reports.map(report => {
            const reportURL = getReportURLForType(report.menuItemKey);

            return (
              reportURL && (
                <CardLink
                  key={reportURL}
                  reportURL={reportURL}
                  report={report}
                />
              )
            );
          })
        )}
      </div>
    </div>
  );
};
