import React from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { Button } from '@rentacenter/racstrap';

// import { ReportsRoute } from '../../config/route-config';

export const reportsBackButtonTestId = 'reportsBackButtonTestId';

export const BackButton = ({ disabled = false }: { disabled: boolean }) => {
  const history = useHistory();

  const handleClick = () => {
    history.goBack()
    // history.push(ReportsRoute.Index);
  };

  return (
    <Button
      outlined
      icon={<ArrowBackIcon />}
      onClick={handleClick}
      disabled={disabled}
      data-testid={reportsBackButtonTestId}
    >
      Back
    </Button>
  );
};
