import React, { useCallback, useContext } from 'react';
import { StoreContext } from '../../../context/store/StoreProvider';
import { FileType, LinkAction } from '../../../domain/Reports/Reports';
import { faEye, faFileCsv, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { getHistoryReportUrl } from '../../../api/api';

import styles from './ActionLink.module.scss';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

interface ActionLinkProps {
  reportId: string;
  fileType: FileType;
  action: LinkAction;
}

export const downloadLinkTestId = 'downloadLinkTestId';
export const viewLinkTestId = 'viewLinkTestId';

export const ActionLink = ({ reportId, fileType, action }: ActionLinkProps) => {
  const { selectedStore } = useContext(StoreContext);
  const icon =
    action === LinkAction.View
      ? faEye
      : fileType === FileType.PDF
      ? faFilePdf
      : faFileCsv;

  const fetchReportLink = useCallback(() => {
    if (!selectedStore) return;
    const storeId = String(+selectedStore);
    getHistoryReportUrl(storeId, reportId, {
      action: action,
      extension: fileType
    }).then(data => {
      const tempLink = document.createElement('a');
      if (action === LinkAction.View) {
        tempLink.setAttribute('target', '_blank');
      }
      tempLink.href = data?.url;
      tempLink.click();
    });
  }, [action, fileType, reportId, selectedStore]);

  const handleDownload = () => {
    fetchReportLink();
  };

  return (
    <button
      className={styles.action}
      onClick={handleDownload}
      data-testid={
        action === LinkAction.View ? viewLinkTestId : downloadLinkTestId
      }
    >
      <FontAwesomeIcon
        icon={icon}
        className={
          fileType === FileType.PDF ? styles.viewAsPdf : styles.viewAsCSV
        }
      />
      {action === LinkAction.View ? 'View' : 'Download'} {fileType}
    </button>
  );
};
