import React, { ReactNode, createContext, useState, useEffect } from 'react';
import { getUserPreferences, setUserPreferences } from '../../api/api';

export interface StoreState {
  selectedStore?: string;
  setSelectedStore?: (storeId: string) => void;
  loadingPreferences?: boolean;
}

export const ProductTypeReport = 'RPDREPORTS';

export const StoreContext = createContext<StoreState>({});

export const StoreProvider = (props: { children: ReactNode }) => {
  const [selectedStore, setSelectedStoreState] = useState<any>('');
  const [loadingPreferences, setLoadingPreferences] = useState(true);

  useEffect(() => {
    getUserPreferences(ProductTypeReport).then(preferences => {
      if (preferences) {
        const lastViewedStore = preferences.filter(
          (item: any) => item.productType === ProductTypeReport)[0]?.preferences?.lastViewedStore;
        if (lastViewedStore) {
          setSelectedStoreState(lastViewedStore);
        }
        else {
          setSelectedStoreState(window.sessionStorage.getItem('storeNumber'));

        }
      }
      setLoadingPreferences(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setSelectedStore = (storeId: string) => {
    setSelectedStoreState(storeId);
    setUserPreferences(ProductTypeReport, {
      op: 'add',
      path: '/lastViewedStore',
      value: storeId
    });
  };

  return (
    <StoreContext.Provider
      value={{
        selectedStore,
        setSelectedStore,
        loadingPreferences
      }}
    >
      {props.children}
    </StoreContext.Provider>
  );
};
