import React, { useContext } from 'react';
import { Button, DatePicker, Select } from '@rentacenter/racstrap';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { format } from 'date-fns';

import styles from './TransactionAuditTrail.module.scss';

import { StoreContext } from '../../../context/store/StoreProvider';
import { useDateRangeValidator } from '../../../utils/useRangeValidator';
import { Footer } from '../../layout/footer/Footer';
import { BackButton } from '../BackButton';
import { ONE_YEAR_AGO } from '../constants';
import {
  GenerateReportType,
  SubReportTypeNames
} from '../../../domain/Reports/Reports';
import { makeOptions } from '../ReceiptAuditTrail/ReceiptAuditTrail';
import { useEmployeeOptions } from '../useEmployeeOptions';
import { useGenerateReport } from '../useGenerateReport';

interface TransactionAuditTrail {
  readonly startDate: Date;
  readonly endDate: Date;
  readonly employeeName?: string;
  readonly subReportType?: string;
}

const transactionAuditFormId = 'transactionAuditFormId';

export const TransactionAuditTrail = () => {
  const { selectedStore } = useContext(StoreContext);

  const { pending, generateReport } = useGenerateReport();
  const { employeesOptions, employeesApiError } = useEmployeeOptions();

  const { control, handleSubmit, errors, clearErrors, getValues } = useForm<
    TransactionAuditTrail
  >({
    mode: 'all',
    defaultValues: {
      employeeName: 'All',
      subReportType: 'All'
    }
  });

  const onSubmit = (data: TransactionAuditTrail) => {
    if (!selectedStore) return;

    generateReport(selectedStore, {
      reportType: GenerateReportType.TRANSACTION_AUDIT_TRAIL,
      filters: {
        startDate: format(data?.startDate, 'MM/dd/yyyy'),
        endDate: format(data?.endDate, 'MM/dd/yyyy'),
        empId:
          (data?.employeeName !== 'All' && data?.employeeName) || undefined,
        subReportType:
          (data?.subReportType !== 'All' && data?.subReportType) || undefined
      }
    });
  };

  const startDateFieldName = 'startDate';
  const endDateFieldName = 'endDate';

  const { startDate, endDate } = getValues();
  const { isAfterEnd, isBeforeStart } = useDateRangeValidator(
    startDate,
    endDate
  );

  return (
    <form id={transactionAuditFormId} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formTitle}>Filter By</div>
      <div className={styles.dateRange}>
        <div className={styles.datePicker}>
          <Controller
            control={control}
            name={startDateFieldName}
            defaultValue={null}
            rules={{
              required: 'This field is required',
              validate: isAfterEnd
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <DatePicker
                required
                label="Start Date"
                placeholder="Select a date"
                name={name}
                onChange={date => {
                  clearErrors('endDate');
                  onChange(date);
                }}
                value={value}
                invalid={invalid}
                datePickerProps={{
                  minDate: ONE_YEAR_AGO,
                  disableFuture: true
                }}
                errorMessage={errors?.startDate?.message}
              />
            )}
          />
        </div>
        <div className={clsx(styles.datePicker, styles.right)}>
          <Controller
            control={control}
            name={endDateFieldName}
            defaultValue={null}
            rules={{
              required: 'This field is required',
              validate: isBeforeStart
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <DatePicker
                required
                placeholder="Select a date"
                label="End Date"
                name={name}
                onChange={e => {
                  clearErrors('startDate');
                  onChange(e);
                }}
                value={value}
                invalid={invalid}
                datePickerProps={{
                  minDate: ONE_YEAR_AGO,
                  disableFuture: true
                }}
                errorMessage={errors?.endDate?.message}
              />
            )}
          />
        </div>
      </div>
      <div className={styles.selectGroup}>
        <Controller
          control={control}
          name="employeeName"
          render={({ onChange, value, name }) => {
            return (
              <Select
                name={name}
                placeholder="Select Employee Name"
                label="Employee Name"
                options={employeesOptions}
                value={value}
                size="large"
                onChange={onChange}
                className={clsx(styles.datePicker, styles.employee)}
                disabled={!!employeesApiError}
                errorMessage={employeesApiError}
              />
            );
          }}
        />
        <Controller
          control={control}
          name="subReportType"
          render={({ onChange, value, name }) => {
            return (
              <Select
                name={name}
                placeholder="Select Sub Report Type"
                label="Sub Report Types"
                options={makeOptions(SubReportTypeNames)}
                value={value}
                size="large"
                onChange={onChange}
                className={clsx(styles.datePicker, styles.subReportType)}
              />
            );
          }}
        />
      </div>
      <div className={styles.suggestTimePeriodText}>
        Suggested search period is up to 4 weeks.
      </div>

      <Footer>
        <BackButton disabled={pending} />
        <Button type="submit" disabled={pending}>
          Generate Report
        </Button>
      </Footer>
    </form>
  );
};
