import React, { createElement, PropsWithChildren } from 'react';
import Tooltip, {
  TooltipProps as MaterialTooltipProps
} from '@material-ui/core/Tooltip';

import styles from './TooltipItem.module.scss';
import clsx from 'clsx';

export const tooltipItemTestId = 'tooltipItemTestId';

export interface TooltipProps extends MaterialTooltipProps {
  as?: string;
}

export const TooltipItem = ({
  title,
  as = 'div',
  children,
  classes,
  ...rest
}: PropsWithChildren<TooltipProps>) => {
  const content = createElement(as, {}, children);
  return (
    <Tooltip
      data-testid={tooltipItemTestId}
      arrow
      classes={{
        ...classes,
        arrow: styles.arrow,
        tooltip: clsx(styles.tooltip, classes?.tooltip)
      }}
      placement="bottom-start"
      title={title || ''}
      {...rest}
    >
      {content}
    </Tooltip>
  );
};
