import React, { useContext } from 'react';
import { Button, DatePicker } from '@rentacenter/racstrap';
import { useForm, Controller } from 'react-hook-form';
import clsx from 'clsx';
import { format } from 'date-fns';

import styles from '../TransactionAuditTrailReport/TransactionAuditTrail.module.scss';

import { useDateRangeValidator } from '../../../utils/useRangeValidator';
import { StoreContext } from '../../../context/store/StoreProvider';
import { Footer } from '../../layout/footer/Footer';
import { BackButton } from '../BackButton';
import { SIX_MONTHS_AGO } from '../constants';
import { GenerateReportType } from '../../../domain/Reports/Reports';
import { useGenerateReport } from '../useGenerateReport';

interface ReportAuditTrail {
  readonly startDate: Date;
  readonly endDate: Date;
}

const reportAuditTrailFormId = 'reportAuditTrailFormId';

export const ReportAuditTrail = () => {
  const { selectedStore } = useContext(StoreContext);
  const { pending, generateReport } = useGenerateReport();

  const { control, handleSubmit, errors, clearErrors, getValues } = useForm<
    ReportAuditTrail
  >({
    mode: 'all'
  });

  const onSubmit = (data: ReportAuditTrail) => {
    if (!selectedStore) return;

    generateReport(selectedStore, {
      reportType: GenerateReportType.REPORT_AUDIT_TRAIL,
      filters: {
        startDate: format(data?.startDate, 'MM/dd/yyyy'),
        endDate: format(data?.endDate, 'MM/dd/yyyy')
      }
    });
  };

  const startDateFieldName = 'startDate';
  const endDateFieldName = 'endDate';

  const { startDate, endDate } = getValues();
  const { isAfterEnd, isBeforeStart } = useDateRangeValidator(
    startDate,
    endDate
  );

  return (
    <form id={reportAuditTrailFormId} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formTitle}>Filter By</div>
      <div className={styles.dateRange}>
        <div className={styles.datePicker}>
          <Controller
            control={control}
            name={startDateFieldName}
            defaultValue={null}
            rules={{
              required: 'This field is required',
              validate: isAfterEnd
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <DatePicker
                required
                label="Start Date"
                placeholder="Select a date"
                name={name}
                onChange={date => {
                  clearErrors('endDate');
                  onChange(date);
                }}
                value={value}
                invalid={invalid}
                datePickerProps={{
                  minDate: SIX_MONTHS_AGO,
                  disableFuture: true
                }}
                errorMessage={errors?.startDate?.message}
              />
            )}
          />
        </div>
        <div className={clsx(styles.datePicker, styles.right)}>
          <Controller
            control={control}
            name={endDateFieldName}
            defaultValue={null}
            rules={{
              required: 'This field is required',
              validate: isBeforeStart
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <DatePicker
                required
                placeholder="Select a date"
                label="End Date"
                name={name}
                onChange={e => {
                  clearErrors('startDate');
                  onChange(e);
                }}
                value={value}
                invalid={invalid}
                datePickerProps={{
                  minDate: SIX_MONTHS_AGO,
                  disableFuture: true
                }}
                errorMessage={errors?.endDate?.message}
              />
            )}
          />
        </div>
      </div>
      <Footer>
        <BackButton disabled={pending} />
        <Button type="submit" disabled={pending}>
          Generate Report
        </Button>
      </Footer>
    </form>
  );
};
