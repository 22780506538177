import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { ToastType, useToastActionsContext } from '@rentacenter/racstrap';

import { ReportsRoute } from '../../config/route-config';
import { generateReport } from '../../api/api';

export function useGenerateReport() {
  const history = useHistory();
  const { showToast } = useToastActionsContext();

  const [pending, setPending] = useState<boolean>(false);

  const callApi = (storeId: string, payload: Record<string, any>) => {
    setPending(true);

    generateReport(storeId, payload)
      .then(response => {
        if (response) {
          history.push(ReportsRoute.REPORT_HISTORY);
        }
      })
      .catch(error => {
        console.error(error);

        showToast(ToastType.Error, 'Cannot generate now, try again later.');

        history.push(ReportsRoute.Index);
      })
      .finally(() => setPending(false));
  };

  return { pending, generateReport: callApi };
}
