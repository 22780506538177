import React, { useContext } from 'react';
import { Button, Card } from '@rentacenter/racstrap';
import styles from '../TransactionAuditTrailReport/TransactionAuditTrail.module.scss';

import { StoreContext } from '../../../context/store/StoreProvider';
import { Footer } from '../../layout/footer/Footer';
import { BackButton } from '../BackButton';
import { GenerateReportType } from '../../../domain/Reports/Reports';
import { useGenerateReport } from '../useGenerateReport';

const ItemsBeingServicedFormId = 'ItemsBeingServicedFormId';

export const ItemsBeingServiced = () => {
  const { selectedStore } = useContext(StoreContext);

  const { pending, generateReport } = useGenerateReport();

  const onSubmitData = () => {
    if (!selectedStore) return;
    const payload = {
      reportType: GenerateReportType.ITEMS_BEING_SERVICED
    };

    generateReport(selectedStore, payload);
  };

  return (
    <form id={ItemsBeingServicedFormId}>
      <div className={styles.formTitleNew}>Filter By</div>
      <div className={styles.dateRange}>
        <Card className={styles.cardStyle}>
          <div>
            This report type does not have the option to add filter
            <br />
            You can directly Generate the Report
          </div>
        </Card>
      </div>
      <Footer>
        <BackButton disabled={pending} />
        <Button type="submit" disabled={pending} onClick={onSubmitData}>
          Generate Report
        </Button>
      </Footer>
    </form>
  );
};
