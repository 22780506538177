import React, { HTMLProps, PropsWithChildren } from 'react';
import styles from './Chip.module.scss';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
interface ChipProps extends HTMLProps<HTMLDivElement> {
  className?: string;
  selected?: boolean;
  showClose?: boolean;
  onCloseClick?: (event: React.MouseEvent<SVGSVGElement>) => void;
}

export const Chip = (props: PropsWithChildren<ChipProps>) => {
  const { children, className, selected, showClose, onCloseClick } = props;

  return (
    <div
      className={clsx(
        styles.chip,
        selected ? styles.selected : styles.defaultBorderColor,
        className
      )}
      onClick={props.onClick}
    >
      <span className={clsx(styles.label)}>
        {children}
        {showClose && onCloseClick && (
          <FontAwesomeIcon
            icon={faTimes}
            className={styles.close}
            onClick={onCloseClick}
          />
        )}
      </span>
    </div>
  );
};
