import React, { PropsWithChildren } from 'react';
import { Card } from '@rentacenter/racstrap';

import styles from './ReportPage.module.scss';

interface Props {
  readonly title: string;
}

export const ReportPage = ({ children, title }: PropsWithChildren<Props>) => {
  return (
    <>
      <div className={styles.title}>{title}</div>
      <Card className={styles.card}>{children}</Card>
    </>
  );
};
