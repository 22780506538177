import { isAfter, isBefore, isValid } from 'date-fns';

export const useDateRangeValidator = (
  formStartDate: Date,
  formEndDate: Date
) => {
  const isAfterEnd = (date: Date) => {
    if (!isValid(formEndDate)) {
      return true;
    }

    if (isAfter(date, formEndDate)) {
      return 'Value must be before or same as End Date';
    }
  };

  const isBeforeStart = (date: Date) => {
    if (!isValid(date)) {
      return true;
    }

    if (isBefore(date, formStartDate)) {
      return 'Value must be after or same as Start Date';
    }
  };

  return { isAfterEnd, isBeforeStart };
};
