import React from 'react';
import { Redirect, Route } from 'react-router';
import { RouteComponentProps, Switch } from 'react-router-dom';
import { ToastProvider } from '@rentacenter/racstrap';
import { ReportsRoute } from '../../config/route-config';
import { UserProvider } from '../../context/user/UserProvider';
import { StoreProvider } from '../../context/store/StoreProvider';
import { UserFetcher } from './UserFetcher';
import { availableReports } from '../../domain/Reports/AvailableReports';
import { ReportPage } from '../layout/ReportPage/ReportPage';
import { Main } from '../layout/Main/Main';

export const routesTestId = 'routesTestId';

export const Routes = () => (
  <div data-testid={routesTestId}>
    <UserProvider>
      <UserFetcher />
      <StoreProvider>
        <ToastProvider>
          <Switch>
            <Route
              exact
              path={[ReportsRoute.Index, ReportsRoute.REPORT_HISTORY]}
            >
              <Main />
            </Route>

            {availableReports.map(report => (
              <Route
                exact
                key={report.url}
                path={report.url}
                component={(props: RouteComponentProps) => {
                  return (
                    <ReportPage title={report.title}>
                      <report.component {...props} />
                    </ReportPage>
                  );
                }}
              />
            ))}
            <Route>
              <Redirect to={ReportsRoute.Index} />
            </Route>
          </Switch>{' '}
        </ToastProvider>
      </StoreProvider>
    </UserProvider>
  </div>
);
