import React, { HTMLProps } from 'react';
import clsx from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';

import styles from './ApiError.module.scss';

import { ReactComponent as ApiErrorIcon } from './apiErrorIcon.svg';

export const apiErrorTestId = 'apiErrorTestId';

export interface ApiErrorProps extends HTMLProps<HTMLDivElement> {
  readonly className?: string;
}

export const ApiError = (props: ApiErrorProps) => {
  const { className, ...rest } = props;

  return (
    <div
      className={clsx(styles.apiError, className)}
      data-testid={apiErrorTestId}
      {...rest}
    >
      <ApiErrorIcon />
      <div className={styles.textHead}>
        <FontAwesomeIcon icon={faExclamationCircle} />
        Something went wrong
      </div>
      <div className={styles.text}>
        we are experiencing an internal server problem, please try again later
      </div>
    </div>
  );
};
