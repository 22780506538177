export enum ReportsRoute {
  Index = '/reports',
  REPORT_HISTORY = '/reports/history',
  DAILY_REPORT = '/reports/daily',
  ON_RENT_INVENTORY = '/reports/on-rent-inventory',
  RECEIPT_AUDIT_TRAIL = '/reports/receipt-audit',
  TRANSACTION_AUDIT_TRAIL_REPORT = '/reports/transaction-audit',
  REPORT_AUDIT_TRAIL_REPORT = '/reports/report-audit',
  SKIP_STOLEN_REPORT = '/reports/skip-stolen',
  PETTY_CASH_DETAIL_ANALYSIS = '/reports/petty-cash-detail',
  IDLE_INVENTORY = '/reports/idle-inventory',
  INVENTORY_RECAP = '/reports/inventory-recap',
  EXCEPTION_AUDIT_TRAIL = '/reports/exception-audit-trail',
  INVENTORY_IN_AUDIT_TRAIL = '/reports/inventory-in-audit-trail',
  ITEMS_BEING_SERVICED = '/reports/items-being-serviced',
  SALES_PERSON_RECAP_DETAILS = '/reports/sales-person-recap',
  INVENTORY_OUT_AUDIT_TRAIL = '/reports/inventory-out-audit-trail',
  PETTY_CASH_ANALYSIS_SUMMARY = '/reports/petty-cash-analysis-summary',
  DAILY_ACTIVITY_PLANNER_EVENT = '/reports/daily-activity-planner-event'
}
