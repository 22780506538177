import React from 'react';
import { Skeleton } from '@material-ui/lab';

export const reportHistorySkeletonTestId = 'reportHistorySkeletonTestId';
export const numberOfRecords = 5;

export const ReportsHistorySkeleton = () => {
  return (
    <>
      {new Array(numberOfRecords).fill(null).map((item, index) => (
        <tr key={index} data-testid={reportHistorySkeletonTestId}>
          <td>
            <Skeleton variant="text" height={30} animation="wave" />
          </td>
          <td>
            <Skeleton variant="text" animation="wave" />
          </td>
          <td>
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
            <Skeleton variant="text" animation="wave" />
          </td>
          <td>
            <Skeleton variant="text" animation="wave" />
          </td>
          <td>
            <Skeleton variant="text" height={30} animation="wave" />
            <Skeleton variant="text" height={30} animation="wave" />
          </td>
        </tr>
      ))}
    </>
  );
};
