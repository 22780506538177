import React from 'react';

import styles from './NoStoreSelected.module.scss';

import { ReactComponent as NoStoreImg } from './no-store-selected.svg';

export const noStoreSelectedTestId = 'noStoreSelectedTestId';

export const NoStoreSelected = () => {
  return (
    <div
      data-testid={noStoreSelectedTestId}
      className={styles.noStoreSelectedWrapper}
    >
      <div className={styles.noStoreSelected}>
        <NoStoreImg height="100" width="180" />
        <h1>No Store Selected</h1>
        <span>
          Please select a store from the dropdown to see the list of reports you
          can generate.
        </span>
      </div>
    </div>
  );
};
