import React, { useContext } from 'react';
import { get } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { Button, DatePicker } from '@rentacenter/racstrap';
import { format } from 'date-fns';

import styles from './PettyCashSummary.module.scss';

import { StoreContext } from '../../../context/store/StoreProvider';
import { Footer } from '../../layout/footer/Footer';
import { BackButton } from '../../reports/BackButton';
import { GenerateReportType } from '../../../domain/Reports/Reports';
import { SIX_MONTHS_AGO } from '../constants';
import { useGenerateReport } from '../useGenerateReport';

interface PettyCashSummary {
  readonly startDate: Date;
}

export const PettyCashSummary = () => {
  const { selectedStore } = useContext(StoreContext);
  const { pending, generateReport } = useGenerateReport();

  const { control, handleSubmit, errors } = useForm<PettyCashSummary>({
    mode: 'all'
  });

  const onSubmit = (data: PettyCashSummary) => {
    if (!selectedStore) return;

    generateReport(selectedStore, {
      reportType: GenerateReportType.PETTY_CASH_SUMMARY,
      filters: {
        startDate: format(data?.startDate, 'MM/dd/yyyy')
      }
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formTitle}>Filter By</div>

      <Controller
        control={control}
        name="startDate"
        defaultValue={null}
        rules={{ required: 'Please select a date' }}
        render={({ onChange, name }, { invalid }) => (
          <DatePicker
            required
            label="Start Date"
            placeholder="Select a date"
            className={styles.datePicker}
            name={name}
            onChange={onChange}
            invalid={invalid}
            datePickerProps={{
              minDate: SIX_MONTHS_AGO,
              disableFuture: true
            }}
            errorMessage={get(errors, name)?.message}
          />
        )}
      />
      <Footer>
        <BackButton disabled={pending} />
        <Button type="submit" disabled={pending}>
          Generate Report
        </Button>
      </Footer>
    </form>
  );
};
