import { useContext, useEffect, useState } from 'react';
import { SelectOption } from '@rentacenter/racstrap';

import { Employee } from '../../domain/User/Employee';
import { getEmployeeList } from '../../api/api';
import { StoreContext } from '../../context/store/StoreProvider';
import { filterUnavailable } from './OnRentInventory/OnRentInventory';

const makeEmployeesOptions = (employees: Employee[]) => {
  return employees.map(employee => ({
    label: `${employee.firstName} ${employee.lastName}`,
    value: employee.userId
  }));
};

export function useEmployeeOptions() {
  const [employeesOptions, setEmployeesOptions] = useState<SelectOption[]>([
    { label: 'All', value: 'All' }
  ]);
  const [employeesApiError, setEmployeesApiError] = useState<string>();

  const { selectedStore } = useContext(StoreContext);

  useEffect(() => {
    if (!selectedStore) return;

    getEmployeeList(selectedStore)
      .then(response => {
        const employeeList: Employee[] = response?.employeeInfoList;
        const options = makeEmployeesOptions(employeeList);
        setEmployeesOptions(employeesOptions =>
          employeesOptions.concat(options)
        );
      })
      .catch(() => {
        setEmployeesApiError(filterUnavailable);
      });
  }, [selectedStore]);

  return { employeesOptions, employeesApiError };
}
