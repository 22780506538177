import React from 'react';

import styles from './ReportDetails.module.scss';

import {
  DailyReportFilters,
  DateRangeFilter,
  GenerateReportType,
  IdleInventoryReportFilters,
  OnRentInventoryFilters,
  ReceiptAuditTrailFilters,
  PettyCashDetailFilters,
  ExceptionAuditTrailFilters,
  ReportFilters,
  SubReportTypeNames,
  TransactionAuditTrailFilters,
  PettyCashSummaryFilters,
  DAPEventReportFilters
} from '../../../domain/Reports/Reports';
import {
  FreeTimeExtensionsNames,
  OptionalFilterNames,
  PaymentOriginNames,
  ReceiptOptionNames
} from '../../reports/ReceiptAuditTrail/ReceiptAuditTrail';
import { CustomerTypeNames } from '../../reports/OnRentInventory/OnRentInventory';
import {
  EventTypeNames,
  EventType
} from '../../reports/DAPEventReport/DAPEventReport';

interface ReportDetailsProps {
  readonly type: GenerateReportType;
  readonly reportFilters: ReportFilters;
  readonly getEmployeeNames: (empId: string | string[]) => string;
  readonly getRouteName: (roleCode: string) => string;
}

interface DateRangeProps {
  readonly startDate: string;
  readonly endDate: string;
}

interface ReportFilterProps {
  readonly name: string;
  readonly value: string;
}

export const ReportFilter = ({ name, value }: ReportFilterProps) => {
  return (
    <div className={styles.row}>
      <span className={styles.filterName}>{name}</span>
      {value}
    </div>
  );
};

export const DateRange = ({ startDate, endDate }: DateRangeProps) => {
  return (
    <>
      <ReportFilter name="Start date: " value={startDate} />
      <ReportFilter name="End date: " value={endDate} />
    </>
  );
};

const pettyCashFunc = (
  reportFilters: ReportFilters,
  getEmployeeNames: (empId: string | string[]) => string
) => {
  const details = reportFilters as PettyCashDetailFilters;
  const employeeName = getEmployeeNames(details.empId);
  return (
    <>
      <DateRange startDate={details.startDate} endDate={details.endDate} />
      {details.empId && (
        <ReportFilter name="Employee Name: " value={employeeName} />
      )}
    </>
  );
};

const exceptionAuditTrailFunc = (
  reportFilters: ReportFilters,
  getEmployeeNames: (empId: string | string[]) => string
) => {
  const details = reportFilters as ExceptionAuditTrailFilters;
  const empId = getEmployeeNames(details.empId);
  return (
    <>
      <DateRange startDate={details.startDate} endDate={details.endDate} />
      {details.empId && <ReportFilter name="Employee Name: " value={empId} />}
      <ReportFilter
        name="Account Management: "
        value={details.accountManagement}
      />
      <ReportFilter name="Payments:" value={details.payment} />
      <ReportFilter name="Cash Management: " value={details.cashManagement} />
      <ReportFilter name="Inventory: " value={details.inventory} />
      <ReportFilter name="Customer: " value={details.customer} />
      <ReportFilter name="Agreement: " value={details.agreement} />
    </>
  );
};

export const renderDAPReport = (
  reportItem: DAPEventReportFilters,
  getEmployeeNames: (empId: string | string[]) => string
) => {
  const employeeNames = getEmployeeNames(reportItem.coWorkers);

  return (
    <>
      <DateRange
        startDate={reportItem.startDate}
        endDate={reportItem.endDate}
      />
      {reportItem.coWorkers && employeeNames && (
        <ReportFilter name="Coworkers Name: " value={employeeNames} />
      )}
      {reportItem.sources && (
        <ReportFilter
          name="Sources: "
          value={
            reportItem.sources.length === 0
              ? 'All'
              : reportItem.sources.join(', ')
          }
        />
      )}
      {reportItem.statuses && (
        <ReportFilter
          name="Statuses: "
          value={
            reportItem.statuses.length === 0
              ? 'All'
              : reportItem.statuses.join(', ')
          }
        />
      )}
      {reportItem.events && (
        <ReportFilter
          name="Events: "
          value={
            reportItem.events.length === 0
              ? 'All'
              : reportItem.events
                  .map(event => EventTypeNames[event as EventType])
                  .join(', ')
                  .toUpperCase()
          }
        />
      )}
    </>
  );
};

export const ReportDetails = ({
  type,
  reportFilters,
  getEmployeeNames,
  getRouteName
}: ReportDetailsProps) => {
  switch (type) {
    case GenerateReportType.REPORT_AUDIT_TRAIL:
    case GenerateReportType.SALES_PERSON_RECAP:
    case GenerateReportType.INVENTORY_OUT_AUDIT_TRAIL:
    case GenerateReportType.INVENTORY_IN_AUDIT_TRAIL:
    case GenerateReportType.SKIP_STOLEN_SUMMARY: {
      const details = reportFilters as DateRangeFilter;
      return (
        <DateRange startDate={details.startDate} endDate={details.endDate} />
      );
    }
    case GenerateReportType.DAILY_REPORT: {
      const details = reportFilters as DailyReportFilters;
      return (
        <ReportFilter name="Business date: " value={details.businessDate} />
      );
    }
    case GenerateReportType.PETTY_CASH_SUMMARY: {
      const details = reportFilters as PettyCashSummaryFilters;
      return <ReportFilter name="Start date: " value={details.startDate} />;
    }
    case GenerateReportType.INVENTORY_STATUS_IDLE: {
      const details = reportFilters as IdleInventoryReportFilters;
      return (
        <ReportFilter
          name="Idle Inventory Days: "
          value={details.idleInventoryDaysFilter}
        />
      );
    }
    case GenerateReportType.PETTY_CASH_DETAIL: {
      return pettyCashFunc(reportFilters, getEmployeeNames);
    }
    case GenerateReportType.LOANER_REPORT: {
      const details = reportFilters as OnRentInventoryFilters;
      const routeName = details.route ? getRouteName(details.route) : '';
      return (
        <>
          <ReportFilter
            name="Customer Type: "
            value={CustomerTypeNames[details.customerType]}
          />
          {details.isGrouping !== undefined && details.route === null && (
            <ReportFilter
              name="Route: "
              value={
                details.isGrouping ? 'All Routes Grouping' : 'No Route Grouping'
              }
            />
          )}
          {details.route && <ReportFilter name="Route: " value={routeName} />}
        </>
      );
    }
    case GenerateReportType.EXCEPTION_AUDIT_TRAIL: {
      return exceptionAuditTrailFunc(reportFilters, getEmployeeNames);
    }
    case GenerateReportType.RECEIPT_AUDIT_TRAIL: {
      const details = reportFilters as ReceiptAuditTrailFilters;
      const employeeName = getEmployeeNames(details.empId);
      return (
        <>
          <DateRange startDate={details.startDate} endDate={details.endDate} />
          <ReportFilter
            name="Payment origin: "
            value={
              details.paymentOrigin === null
                ? PaymentOriginNames.All
                : PaymentOriginNames[details.paymentOrigin]
            }
          />
          <ReportFilter name="Employee Name: " value={employeeName} />
          {details.receiptOption && (
            <ReportFilter
              name={`${OptionalFilterNames.Receipt}: `}
              value={ReceiptOptionNames[details.receiptOption]}
            />
          )}
          {details.freeTimeExtension && (
            <ReportFilter
              name={`${OptionalFilterNames.FreeTimeExtensions}: `}
              value={FreeTimeExtensionsNames[details.freeTimeExtension]}
            />
          )}
        </>
      );
    }
    case GenerateReportType.TRANSACTION_AUDIT_TRAIL: {
      const details = reportFilters as TransactionAuditTrailFilters;
      const employeeName = getEmployeeNames(details.empId);
      return (
        <>
          <DateRange startDate={details.startDate} endDate={details.endDate} />
          {details.empId && (
            <ReportFilter name="Employee Name: " value={employeeName} />
          )}
          {details.subReportType !== undefined && (
            <ReportFilter
              name="Sub Report Type: "
              value={
                details.subReportType === null
                  ? 'All'
                  : SubReportTypeNames[details.subReportType]
              }
            />
          )}
        </>
      );
    }
    case GenerateReportType.DAILY_ACTIVITY_PLANNER_EVENT: {
      return renderDAPReport(
        reportFilters as DAPEventReportFilters,
        getEmployeeNames
      );
    }
    default:
      return null;
  }
};
