import { GenericObject } from '../utils/GenericObject';
import { client } from './client';
import { ReportUrlParams } from '../domain/Reports/Reports';

export const getCurrentUser = () =>
  client('users/current', { method: 'GET' }, true);

export const getUserPreferences = (productType: string) =>
  client(`users/current/preferences/${productType}`, { method: 'GET' }, true);

export const setUserPreferences = (
  productType: string,
  payload: GenericObject
) =>
  client(
    `users/current/preferences/${productType}`,
    { method: 'PATCH', body: [payload] },
    true
  );

export const getUserStores = () =>
  client(
    'users/current/stores?q=permissions[:includes]=RACPAD_REPORTS',
    { method: 'GET' },
    true
  );

export const getStoreRoutes = (storeId: string) =>
  client(`misc/store-routes/${storeId}`, { method: 'GET' }, true);

export const getReportsForStore = (storeId: string) => {
  return client(`reports/types/${storeId}`, { method: 'GET' }, false);
};

export const getReportsHistory = (storeId: string) => {
  return client(`stores/${storeId}/reports`, { method: 'GET' }, false);
};

export const getHistoryReportUrl = (
  storeId: string,
  reportId: string,
  params?: ReportUrlParams
) => {
  return client(
    `stores/${storeId}/reports/${reportId}/url`,
    { method: 'GET', params },
    false
  );
};

export const generateReport = (storeId: string, payload: GenericObject) =>
  client(
    `reports/generate/${storeId}`,
    { method: 'POST', body: payload },
    false
  );

export const getEmployeeList = (storeId: string) =>
  client(`misc/employees/${storeId}`, { method: 'GET' }, true);
