import React, { useContext, useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faInfoCircle, faSpinner } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { format } from 'date-fns';
import { Table } from '@rentacenter/racstrap';

import styles from './ReportsHistory.module.scss';

import {
  getEmployeeList,
  getReportsHistory,
  getStoreRoutes
} from '../../api/api';
import { StoreContext } from '../../context/store/StoreProvider';
import {
  GenerateReportType,
  GenerateReportTypeNames,
  FileType,
  ReportHistory,
  LinkAction,
  ReportStatus
} from '../../domain/Reports/Reports';
import { ActionLink } from './ActionLink/ActionLink';
import { ReportsHistorySkeleton } from './ReportsHistorySkeleton';
import { ReportDetails } from './ReportDetails/ReportDetails';
import { Employee } from '../../domain/User/Employee';
import { GenericObject } from '../../utils/GenericObject';
import { SelectOption } from '@rentacenter/racstrap/dist';
import { TIME } from '../../utils/time';
import { ApiError } from '../common/ApiError/ApiError';

export const reportHistoryTableTestId = 'reportHistoryTableTestId';
export const reportHistoryRowTestId = 'reportHistoryRowTestId';
export const inProgressTestId = 'inProgressTestId';

export const InProgress = () => (
  <span className={styles.action} data-testid={inProgressTestId}>
    <FontAwesomeIcon
      icon={faSpinner}
      className={clsx('fa-spin', styles.spinnerIcon)}
    />
    In Progress...
  </span>
);

// eslint-disable-next-line
export const ReportsHistory = () => {
  const { selectedStore } = useContext(StoreContext);
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [historyList, setHistoryList] = useState<ReportHistory[]>([]);
  const [hasApiError, setHasApiError] = useState(false);
  const [employeeList, setEmployeeList] = useState<Employee[]>([]);
  const [routeOptions, setRoutesOptions] = useState<SelectOption[]>([]);
  const firstRender = useRef(Date.now());

  const getEmployeeNames = (id: string | string[]): string => {
    if (Array.isArray(id)) {
      if (id.length === 0) return 'All';

      return employeeList
        .filter(e => id.includes(e.userId))
        ?.map(emp => `${emp.firstName} ${emp.lastName}`)
        ?.join(', ');
    } else {
      if (id === '-1') return 'All';

      const employeeItem = employeeList.find(e => e.userId === id);
      return employeeItem !== undefined
        ? `${employeeItem.firstName} ${employeeItem.lastName}`
        : '';
    }
  };

  const getRouteName = (roleCode: string): string => {
    const route = routeOptions.find(r => r.value === roleCode);
    return route !== undefined ? route.label : '';
  };

  const hasPendingReports = () => {
    return !!historyList.find((report: ReportHistory) => {
      return (
        report.reportPDFStatus !== ReportStatus.Completed ||
        report.reportCSVStatus !== ReportStatus.Completed
      );
    });
  };

  const updateHistory = (manualRequest: boolean) => {
    if (!selectedStore) return;

    const isFreshRender = Date.now() - firstRender.current <= 10 * TIME.SECONDS;

    if (!manualRequest && !hasPendingReports() && !isFreshRender) {
      return;
    }

    const storeId = String(+selectedStore);
    manualRequest && setLoadingHistory(true);
    setHasApiError(false);
    getReportsHistory(storeId)
      .then(reportHistory => {
        setHistoryList(reportHistory);
      })
      .catch(() => setHasApiError(true))
      .finally(() => manualRequest && setLoadingHistory(false));
  };

  useEffect(
    function initialFetch() {
      updateHistory(true);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [selectedStore]
  );

  useEffect(() => {
    const updateInterval = setInterval(updateHistory, 5 * TIME.SECONDS);
    return () => {
      clearInterval(updateInterval);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [historyList]);

  useEffect(() => {
    if (!selectedStore) return;
    const hasEmployeeInReport = historyList.some(
      ({ reportType }) =>
        reportType === GenerateReportType.RECEIPT_AUDIT_TRAIL ||
        reportType === GenerateReportType.TRANSACTION_AUDIT_TRAIL ||
        reportType === GenerateReportType.EXCEPTION_AUDIT_TRAIL ||
        reportType === GenerateReportType.PETTY_CASH_DETAIL ||
        reportType === GenerateReportType.DAILY_ACTIVITY_PLANNER_EVENT
    );
    if (!hasEmployeeInReport) return;
    getEmployeeList(selectedStore).then(response => {
      setEmployeeList(response?.employeeInfoList);
    });
  }, [selectedStore, historyList]);

  useEffect(
    function fetchRoutes() {
      if (!selectedStore) return;
      const hasStoreRouteInReport = historyList.some(
        ({ reportType }) => reportType === GenerateReportType.LOANER_REPORT
      );
      if (!hasStoreRouteInReport) return;
      getStoreRoutes(selectedStore).then(data => {
        if (data?.length) {
          setRoutesOptions(
            data.map((item: GenericObject) => {
              const { routeDescription: label, routeCode: value } = item;
              return { label, value };
            })
          );
        }
      });
    },
    [selectedStore, historyList]
  );

  return (
    <div className={styles.reportHistoryContainer}>
      <h1 className={styles.pageTitle}>List of past generated Reports</h1>
      <Table data-testid={reportHistoryTableTestId}>
        <thead>
          <tr>
            <th scope="col" className={styles.reportType}>
              <span className={styles.label}>Report Type</span>
            </th>
            <th scope="col" className={styles.timeGenerated}>
              <span className={styles.label}>Time & Date Generated</span>
            </th>
            <th scope="col" className={styles.details}>
              <span className={styles.label}>Details</span>
            </th>
            <th scope="col" className={styles.view}>
              <span className={styles.label}>View</span>
            </th>
            <th scope="col" className={styles.download}>
              <span className={styles.label}>Download</span>
            </th>
          </tr>
        </thead>
        <tbody>
          {loadingHistory ? (
            <ReportsHistorySkeleton />
          ) : historyList.length > 0 ? (
            historyList.map((history: ReportHistory, index) => (
              <tr key={index} data-testid={reportHistoryRowTestId}>
                <td>{GenerateReportTypeNames[history.reportType]}</td>
                <td>
                  {format(
                    new Date(history?.createdDate),
                    'hh:mm aa, MM/dd/yyyy'
                  )}
                </td>
                <td>
                  <ReportDetails
                    type={history.reportType}
                    reportFilters={history.reportFilters}
                    getEmployeeNames={getEmployeeNames}
                    getRouteName={getRouteName}
                  />
                </td>
                <td>
                  {history.reportPDFStatus === ReportStatus.Completed && (
                    <ActionLink
                      reportId={history.id}
                      action={LinkAction.View}
                      fileType={FileType.PDF}
                    />
                  )}
                </td>
                <td>
                  {history.reportPDFStatus === ReportStatus.Completed ? (
                    <ActionLink
                      reportId={history.id}
                      action={LinkAction.Download}
                      fileType={FileType.PDF}
                    />
                  ) : (
                    <InProgress />
                  )}
                  {history.reportCSVStatus === ReportStatus.Completed ? (
                    <ActionLink
                      reportId={history.id}
                      action={LinkAction.Download}
                      fileType={FileType.CSV}
                    />
                  ) : (
                    <InProgress />
                  )}
                </td>
              </tr>
            ))
          ) : !hasApiError ? (
            <tr>
              <td colSpan={5} className={styles.empty}>
                <FontAwesomeIcon
                  icon={faInfoCircle}
                  className={styles.emptyIcon}
                />
                No Past Generated Reports Have Been Found
              </td>
            </tr>
          ) : null}
        </tbody>
      </Table>
      {hasApiError && <ApiError className={styles.apiError} />}
    </div>
  );
};
