import { SearchableSelect, SelectOption, Label } from '@rentacenter/racstrap';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import styles from './StoreSelector.module.scss';
import { TooltipItem } from '../common/TooltipItem/TooltipItem';
import { StoreContext } from '../../context/store/StoreProvider';
import { getUserStores } from '../../api/api';

export const storeListTestId = 'storeListTestId';

export const getOptions = (stores: any): SelectOption[] => {
  return stores.map(
    (store: any) =>
      ({
        label: `${store.storeNum} - ${store.storeName} | ${store.storeState}`,
        value: store.storeNum
      } as SelectOption)
  ) as SelectOption[];
};

export const StoreSelector = () => {
  const { selectedStore, setSelectedStore, loadingPreferences } = useContext(
    StoreContext
  );
  const [loadingStores, setLoadingStores] = useState<boolean>(true);
  const [storeOptions, setStoreOptions] = useState<SelectOption[]>([]);
  const [openTooltip, setOpenTooltip] = useState<boolean>(false);

  useEffect(() => {
    getUserStores().then(stores => {
      const options = getOptions(stores);

      if (stores.length === 1) {
        const storeId = stores[0]?.storeNum;
        setSelectedStore?.(storeId);
      }

      setStoreOptions(options);
      setLoadingStores(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!loadingStores && !loadingPreferences)
      setOpenTooltip(selectedStore === '');
  }, [loadingPreferences, loadingStores, selectedStore]);

  // A user can be temporarily assigned to a store.
  // If the given store is saved in the coworker's preferences,
  // but removed from it's store list, the selected store is expected to fallback to the first store from the list.
  const handleCaseWhenPreferredStoreHasBeenRemoved = useCallback(() => {
    if (!storeOptions?.length) {
      return;
    }

    setSelectedStore?.(storeOptions[0].value);
  }, [storeOptions, setSelectedStore]);

  useEffect(() => {
    if (!selectedStore || !storeOptions?.length) return;

    const store = storeOptions.find(
      (storeObj: any) => storeObj.value === selectedStore
    );

    if (!store) {
      handleCaseWhenPreferredStoreHasBeenRemoved();
    }
  }, [handleCaseWhenPreferredStoreHasBeenRemoved, selectedStore, storeOptions]);

  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        <Label forInput="selectStore">Select Store:</Label>
        <TooltipItem
          className={styles.tooltipWrapper}
          title={
            <>
              Select store
              <br /> from here.
            </>
          }
          open={openTooltip}
          placement="bottom-end"
          TransitionProps={{
            exit: false
          }}
        >
          <div
            onClick={() => setOpenTooltip(false)}
            onMouseEnter={() => setOpenTooltip(true)}
            onMouseLeave={() => setOpenTooltip(false)}
          >
            <SearchableSelect
              classes={{ selection: styles.selection, select: styles.select }}
              size="large"
              name="selectStore"
              placeholder="Select Store"
              options={storeOptions}
              onChange={setSelectedStore}
              value={selectedStore}
              disabled={storeOptions?.length <= 1}
              isLoading={loadingStores}
              data-testid={storeListTestId}
            />
          </div>
        </TooltipItem>
      </div>
    </div>
  );
};
