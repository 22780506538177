import React, { useContext } from 'react';
import {
  Button,
  Checkbox,
  DatePicker,
  SearchableMultiSelect,
  SelectOption
} from '@rentacenter/racstrap';
import { Footer } from '../../layout/footer/Footer';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useForm, Controller, NestedValue, useWatch } from 'react-hook-form';

import styles from './DAPEventReport.module.scss';

import { BackButton } from '../BackButton';
import { StoreContext } from '../../../context/store/StoreProvider';
import { GenerateReportType } from '../../../domain/Reports/Reports';
import { useDateRangeValidator } from '../../../utils/useRangeValidator';
import { SIX_MONTHS_AGO } from '../constants';
import { useEmployeeOptions } from '../useEmployeeOptions';
import { useGenerateReport } from '../useGenerateReport';
import { makeOptions } from '../ReceiptAuditTrail/ReceiptAuditTrail';
import { Chip } from '../../common/Chip/Chip';

enum Status {
  ToDo = 'TODO',
  Completed = 'COMPLETED',
  Canceled = 'CANCELLED',
  Deleted = 'DELETED'
}

export const StatusNames: Record<Status, string> = {
  [Status.ToDo]: 'ToDo',
  [Status.Completed]: 'Completed',
  [Status.Canceled]: 'Cancelled',
  [Status.Deleted]: 'Deleted'
};

enum Source {
  Support = 'SUPPORT',
  Store = 'STORE',
  Online = 'ONLINE'
}

export const SourceNames: Record<Source, string> = {
  [Source.Support]: 'Support',
  [Source.Store]: 'Store',
  [Source.Online]: 'Online'
};

export enum EventType {
  Delivery = 'DELIVERY',
  Return = 'RETURN',
  Service = 'SERVICE',
  CarryIn = 'CARRY_IN',
  CarryOut = 'CARRY_OUT',
  ServicePickup = 'SERVICE_PICKUP',
  ServiceDelivery = 'SERVICE_DELIVERY',
  ServiceCarryIn = 'SERVICE_CARRY_IN',
  ServiceCarryOut = 'SERVICE_CARRY_OUT',
  SwitchOut = 'SWITCH_OUT',
  Store = 'STORE_EVENT',
  BlockTime = 'BLOCK_EVENT'
}

export const EventTypeNames: Record<EventType, string> = {
  [EventType.Delivery]: 'Delivery',
  [EventType.Return]: 'Return',
  [EventType.Service]: 'Service',
  [EventType.CarryIn]: 'CarryIn',
  [EventType.CarryOut]: 'CarryOut',
  [EventType.ServicePickup]: 'ServicePickup',
  [EventType.ServiceDelivery]: 'ServiceDelivery',
  [EventType.ServiceCarryIn]: 'ServiceCarryIn',
  [EventType.ServiceCarryOut]: 'ServiceCarryOut',
  [EventType.SwitchOut]: 'Switchout',
  [EventType.Store]: 'StoreEvent',
  [EventType.BlockTime]: 'BlockEvent'
};

interface DAPEventReportForm {
  readonly startDate: Date;
  readonly endDate: Date;
  readonly coWorkers: NestedValue<string[]>;
  readonly events: NestedValue<string[]>;
  readonly sources: NestedValue<string[]>;
  readonly statuses: NestedValue<string[]>;
}

interface DAPEventReportPayload {
  reportType: GenerateReportType;
  filters: DAPEventReportFilters;
}

interface DAPEventReportFilters {
  startDate: string;
  endDate: string;
  coWorkers: NestedValue<string[]>;
  events: NestedValue<string[]>;
  sources: NestedValue<string[]>;
  statuses: NestedValue<string[]>;
}

enum FormFields {
  startDate = 'startDate',
  endDate = 'endDate',
  events = 'events',
  coWorkers = 'coWorkers',
  sources = 'sources',
  statuses = 'statuses'
}

const renderOption = (option: SelectOption) => {
  return (
    <div>
      <Checkbox
        labelText={option.label}
        size="medium"
        checked={option.selected}
      />
    </div>
  );
};

const renderSelectedValues = (options: SelectOption[]) => (
  <>
    {options.map((option, index) => (
      <span key={index}>{option.label} </span>
    ))}
  </>
);

const getSelectedCoworkerNames = (
  selectedCoworkers: SelectOption[],
  selectedCoworkerIds: string[]
) =>
  selectedCoworkers
    .filter(coworker => selectedCoworkerIds.includes(coworker.value))
    ?.map(coworker => coworker.label);

const dailyActivityPlannerDetailId = 'dailyActivityPlannerDetailId';
const requiredErrorMessage = 'This field is required';
const defaultFilterListValue = ([] as unknown) as NestedValue<string[]>;

export const DAPEventReport = () => {
  const { selectedStore } = useContext(StoreContext);
  const { pending, generateReport } = useGenerateReport();
  const { employeesOptions, employeesApiError } = useEmployeeOptions();

  const { control, handleSubmit, errors, getValues, clearErrors } = useForm<
    DAPEventReportForm
  >({
    mode: 'onChange',
    defaultValues: {
      coWorkers: ['All'],
      sources: ['All'],
      events: ['All'],
      statuses: ['All']
    }
  });

  const coWorkers: string[] =
    useWatch({ control, name: FormFields.coWorkers }) || [];
  const showSelectedCoworkers =
    coWorkers.length > 0 && !coWorkers.includes('All');

  const onSubmit = (data: DAPEventReportForm) => {
    if (!selectedStore) return;

    const payload: DAPEventReportPayload = {
      reportType: GenerateReportType.DAILY_ACTIVITY_PLANNER_EVENT,
      filters: {
        startDate: format(data?.startDate, 'MM/dd/yyyy'),
        endDate: format(data?.endDate, 'MM/dd/yyyy'),
        coWorkers: data.coWorkers?.includes('All')
          ? defaultFilterListValue
          : data.coWorkers,
        sources: data.sources?.includes('All')
          ? defaultFilterListValue
          : data.sources,
        events: data.events?.includes('All')
          ? defaultFilterListValue
          : data.events,
        statuses: data.statuses?.includes('All')
          ? defaultFilterListValue
          : data.statuses
      }
    };

    generateReport(selectedStore, payload);
  };

  const { startDate, endDate } = getValues();

  const { isAfterEnd, isBeforeStart } = useDateRangeValidator(
    startDate,
    endDate
  );

  const isValidSelection = (value: any) => value.length || requiredErrorMessage;

  return (
    <>
      <div className={styles.cardTitle}>Filter By</div>
      <form id={dailyActivityPlannerDetailId} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.dateRange}>
          <div className={styles.datePicker} data-testid="startDate">
            <Controller
              control={control}
              name={FormFields.startDate}
              defaultValue={null}
              rules={{
                required: requiredErrorMessage,
                validate: isAfterEnd
              }}
              render={({ onChange, value, name }, { invalid }) => (
                <DatePicker
                  required
                  label="Start Date"
                  placeholder="Select a date"
                  name={name}
                  onChange={date => {
                    clearErrors(FormFields.endDate);
                    onChange(date);
                  }}
                  value={value}
                  invalid={invalid}
                  datePickerProps={{
                    minDate: SIX_MONTHS_AGO,
                    disableFuture: true
                  }}
                  errorMessage={errors?.startDate?.message}
                />
              )}
            />
          </div>
          <div
            className={clsx(styles.datePicker, styles.right)}
            data-testid="endDate"
          >
            <Controller
              control={control}
              name={FormFields.endDate}
              defaultValue={null}
              rules={{
                required: requiredErrorMessage,
                validate: isBeforeStart
              }}
              render={({ onChange, value, name }, { invalid }) => (
                <DatePicker
                  required
                  placeholder="Select a date"
                  label="End Date"
                  name={name}
                  onChange={date => {
                    clearErrors(FormFields.startDate);
                    onChange(date);
                  }}
                  value={value}
                  invalid={invalid}
                  datePickerProps={{
                    minDate: SIX_MONTHS_AGO,
                    disableFuture: true
                  }}
                  errorMessage={errors?.endDate?.message}
                />
              )}
            />
          </div>
        </div>
        <div
          className={clsx(
            styles.selectGroup1,
            styles.marginNegative,
            styles.marginTopMedium
          )}
        >
          <Controller
            control={control}
            name={FormFields.events}
            rules={{
              validate: isValidSelection
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <SearchableMultiSelect
                name={name}
                label="Event Types"
                options={makeOptions(EventTypeNames, true)}
                onChange={onChange}
                value={value}
                size="large"
                className={clsx(styles.datePicker, styles.employee)}
                renderOption={renderOption}
                renderSelectedValues={renderSelectedValues}
                selectAllOptionValue="All"
                maxCharacterDisplayed={20}
                required
                invalid={invalid}
                errorMessage={errors?.events?.message}
              />
            )}
          />
          <Controller
            control={control}
            name={FormFields.sources}
            rules={{
              validate: isValidSelection
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <SearchableMultiSelect
                name={name}
                label="Sources"
                options={makeOptions(SourceNames, true)}
                onChange={onChange}
                value={value}
                size="large"
                className={clsx(styles.datePicker, styles.employee)}
                renderOption={renderOption}
                renderSelectedValues={renderSelectedValues}
                selectAllOptionValue="All"
                maxCharacterDisplayed={20}
                required
                invalid={invalid}
                errorMessage={errors?.sources?.message}
              />
            )}
          />
          <Controller
            control={control}
            name={FormFields.statuses}
            rules={{
              validate: isValidSelection
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <SearchableMultiSelect
                name={name}
                label="Status"
                options={makeOptions(StatusNames, true)}
                onChange={onChange}
                value={value}
                size="large"
                className={clsx(styles.datePicker, styles.employee)}
                renderOption={renderOption}
                renderSelectedValues={renderSelectedValues}
                selectAllOptionValue="All"
                maxCharacterDisplayed={20}
                required
                invalid={invalid}
                errorMessage={errors?.statuses?.message}
              />
            )}
          />
        </div>
        <div className={clsx(styles.selectGroup1, styles.marginNegative)}>
          <Controller
            control={control}
            name={FormFields.coWorkers}
            rules={{
              validate: isValidSelection
            }}
            render={({ onChange, value, name }, { invalid }) => (
              <SearchableMultiSelect
                name={name}
                label="Coworkers"
                options={employeesOptions}
                onChange={onChange}
                value={value}
                size="large"
                className={clsx(styles.datePicker, styles.employee)}
                disabled={!!employeesApiError}
                required
                invalid={invalid}
                errorMessage={errors?.coWorkers?.message}
                renderOption={renderOption}
                renderSelectedValues={renderSelectedValues}
                selectAllOptionValue="All"
                maxCharacterDisplayed={20}
              />
            )}
          />
        </div>
        {showSelectedCoworkers && (
          <div className={clsx(styles.selectGroup1, styles.addMaxWidth)}>
            {getSelectedCoworkerNames(employeesOptions, coWorkers).map(
              (coWorker, index) => (
                <Chip
                  // onClick={() => onClick(eventTypeKeys)}
                  selected
                  key={index}
                >
                  {coWorker}
                </Chip>
              )
            )}
          </div>
        )}
        <Footer>
          <BackButton disabled={pending} />
          <Button
            disabled={pending}
            data-testid="generateReport"
            type="submit"
            form={dailyActivityPlannerDetailId}
          >
            Generate Report
          </Button>
        </Footer>
      </form>
    </>
  );
};
