import React, { useContext, useState } from 'react';
import {
  Button,
  DatePicker,
  Radio,
  Select,
  SelectOption
} from '@rentacenter/racstrap';
import { Footer } from '../../layout/footer/Footer';
import clsx from 'clsx';
import { format } from 'date-fns';
import { useForm, Controller } from 'react-hook-form';

import styles from './ReceiptAuditTrail.module.scss';

import { BackButton } from '../BackButton';
import { StoreContext } from '../../../context/store/StoreProvider';
import { GenerateReportType } from '../../../domain/Reports/Reports';
import { useDateRangeValidator } from '../../../utils/useRangeValidator';
import { SIX_MONTHS_AGO } from '../constants';
import { useEmployeeOptions } from '../useEmployeeOptions';
import { useGenerateReport } from '../useGenerateReport';

export enum PaymentOriginKeys {
  ALL = 'All',
  INSTORE = 'INSTORE',
  PHONE = 'PHONE',
  DROP_PAYMENT = 'DROP_PAYMENT',
  MAIL_IN = 'MAIL_IN',
  WEB = 'WEB',
  EXTENSION = 'EXTENSION',
  PETTY_CASH = 'PETTY_CASH',
  ROUTE = 'ROUTE',
  HOME_OFFICE = 'HOME_OFFICE',
  AUTOPAY = 'AUTOPAY',
  ECOMM = 'ECOMM',
  RECURRINGPAY = 'RECURRINGPAY',
  MOBILEPAYMENT = 'MOBILEPAYMENT',
  MARINER = 'MARINER',
  ARCO = 'ARCO',
  SWIFT = 'SWIFT',
  CPORT = 'CPORT',
  THIRD_PARTY_PAYMENT = 'THIRD_PARTY_PAYMENT',
  MERCPREF = 'MERCPREF',
  CONSFEE = 'CONSFEE'
}

export const PaymentOriginNames: Record<PaymentOriginKeys, string> = {
  [PaymentOriginKeys.ALL]: 'All',
  [PaymentOriginKeys.INSTORE]: 'In Store',
  [PaymentOriginKeys.PHONE]: 'Phone',
  [PaymentOriginKeys.DROP_PAYMENT]: 'Drop Payment',
  [PaymentOriginKeys.MAIL_IN]: 'Mail In',
  [PaymentOriginKeys.WEB]: 'Web',
  [PaymentOriginKeys.EXTENSION]: 'Extension',
  [PaymentOriginKeys.PETTY_CASH]: 'Petty Cash',
  [PaymentOriginKeys.ROUTE]: 'Route',
  [PaymentOriginKeys.HOME_OFFICE]: 'Home Office',
  [PaymentOriginKeys.AUTOPAY]: 'AutoPay',
  [PaymentOriginKeys.ECOMM]: 'EComm Payment',
  [PaymentOriginKeys.RECURRINGPAY]: 'Recurring Payment',
  [PaymentOriginKeys.MOBILEPAYMENT]: 'Mobile Payment',
  [PaymentOriginKeys.MARINER]: 'Mariner',
  [PaymentOriginKeys.ARCO]: 'ARC-Online',
  [PaymentOriginKeys.SWIFT]: 'Swift Payment',
  [PaymentOriginKeys.CPORT]: 'Customer Portal',
  [PaymentOriginKeys.THIRD_PARTY_PAYMENT]: 'Third Party Payment',
  [PaymentOriginKeys.MERCPREF]: 'Merchants Preferred',
  [PaymentOriginKeys.CONSFEE]: 'Consignment Fee'
};

export enum ReceiptOptionKeys {
  LIABILITY = 'LIABILITY',
  EPO_CASH_SALE = 'EPO_CASH_SALE',
  RENT = 'RENT',
  CLUB = 'CLUB',
  LDW = 'LDW',
  LATE_FEE = 'LATE_FEE',
  OTHER = 'OTHER'
}

export const ReceiptOptionNames: Record<ReceiptOptionKeys, string> = {
  [ReceiptOptionKeys.LIABILITY]: 'Susp /Liability acct.',
  [ReceiptOptionKeys.EPO_CASH_SALE]: 'EPO/Cash Sale',
  [ReceiptOptionKeys.RENT]: 'Rent',
  [ReceiptOptionKeys.CLUB]: 'Club',
  [ReceiptOptionKeys.LDW]: 'LDW',
  [ReceiptOptionKeys.LATE_FEE]: 'Late Fee',
  [ReceiptOptionKeys.OTHER]: 'Other'
};

export enum FreeTimeExtensionKeys {
  FreeTime = 'FreeTime',
  ServiceExtension = 'ServiceExtension',
  RetentionExtension = 'RetentionExtension',
  ClubClaimExtension = 'ClubClaimExtension',
  PromoFreeTime = 'PromoFreeTime'
}

export const FreeTimeExtensionsNames: Record<FreeTimeExtensionKeys, string> = {
  [FreeTimeExtensionKeys.FreeTime]: 'Free Time',
  [FreeTimeExtensionKeys.ServiceExtension]: 'Service Discount',
  [FreeTimeExtensionKeys.RetentionExtension]: 'Retention Extension',
  [FreeTimeExtensionKeys.ClubClaimExtension]: 'Club Claim Extension',
  [FreeTimeExtensionKeys.PromoFreeTime]: 'Promo Free Time'
};

export enum OptionalFilterKeys {
  Receipt = 'Receipt',
  FreeTimeExtensions = 'FreeTimeExtensions'
}

export const OptionalFilterNames: Record<OptionalFilterKeys, string> = {
  Receipt: 'Receipt',
  FreeTimeExtensions: 'Free Time/Extensions'
};

export const makeOptions = (
  options: Record<string, string>,
  addDefaultAllOption = false
): SelectOption[] => {
  const selectOptions = Object.keys(options).map(key => ({
    label: options[key],
    value: key
  }));

  return addDefaultAllOption
    ? [{ label: 'All', value: 'All' }].concat(selectOptions)
    : selectOptions;
};

interface ReceiptAuditTrailForm {
  readonly startDate: Date;
  readonly endDate: Date;
  readonly paymentOrigin?: string;
  readonly employeeName?: any;
  readonly receiptOption?: any;
  readonly freeTimeExtensions?: any;
}

interface ReceiptAuditTrailPayload {
  reportType: GenerateReportType;
  filters: ReceiptAuditTrailFilters;
}

interface ReceiptAuditTrailFilters {
  readonly startDate: string;
  readonly endDate: string;
  readonly paymentOrigin?: string;
  readonly empId?: string;
  readonly receiptOption?: string;
  readonly freeTimeExtension?: string;
}

enum FormFields {
  startDate = 'startDate',
  endDate = 'endDate',
  paymentOrigin = 'paymentOrigin',
  employeeName = 'employeeName',
  receiptOption = 'receiptOption',
  freeTimeExtensions = 'freeTimeExtensions',
  radio = 'radio'
}

const receiptAuditTrailId = 'receiptAuditTrailId';

const receiptOptions = makeOptions(ReceiptOptionNames);

const freeTimeExtensionOptions = makeOptions(FreeTimeExtensionsNames);

const getEnabledSelect = (option: OptionalFilterKeys) => {
  switch (option) {
    case OptionalFilterKeys.Receipt:
      return FormFields.receiptOption;
    case OptionalFilterKeys.FreeTimeExtensions:
      return FormFields.freeTimeExtensions;
  }
};

export const ReceiptAuditTrail = () => {
  const { selectedStore } = useContext(StoreContext);
  const { pending, generateReport } = useGenerateReport();
  const [enabledSelect, setEnabledSelect] = useState('');
  const { employeesOptions, employeesApiError } = useEmployeeOptions();

  const {
    control,
    handleSubmit,
    errors,
    getValues,
    setValue,
    clearErrors
  } = useForm<ReceiptAuditTrailForm>({
    mode: 'onChange',
    defaultValues: {
      paymentOrigin: PaymentOriginKeys.ALL,
      employeeName: 'All',
      receiptOption: null,
      freeTimeExtensions: null
    }
  });

  const setDefaultFieldValue = (
    fieldName: FormFields.receiptOption | FormFields.freeTimeExtensions
  ) => {
    const defaultFilters: Partial<ReceiptAuditTrailFilters> = {
      receiptOption: receiptOptions[0].value,
      freeTimeExtension: freeTimeExtensionOptions[0].value
    };

    switch (fieldName) {
      case FormFields.receiptOption:
        setValue(FormFields.receiptOption, defaultFilters.receiptOption);
        setValue(FormFields.freeTimeExtensions, null);
        break;
      case FormFields.freeTimeExtensions:
        setValue(FormFields.receiptOption, null);
        setValue(
          FormFields.freeTimeExtensions,
          defaultFilters.freeTimeExtension
        );
        break;
    }
  };

  const isEnabled = (fieldName: string) => {
    return enabledSelect === fieldName;
  };

  const enableSelect = (option: OptionalFilterKeys) => {
    const fieldName = getEnabledSelect(option);
    setEnabledSelect(fieldName);
    setDefaultFieldValue(fieldName);
  };

  const onSubmit = (data: ReceiptAuditTrailForm) => {
    if (!selectedStore) return;

    const payload: ReceiptAuditTrailPayload = {
      reportType: GenerateReportType.RECEIPT_AUDIT_TRAIL,
      filters: {
        startDate: format(data?.startDate, 'MM/dd/yyyy'),
        endDate: format(data?.endDate, 'MM/dd/yyyy'),
        paymentOrigin:
          data.paymentOrigin === PaymentOriginKeys.ALL
            ? undefined
            : data.paymentOrigin,
        empId: data.employeeName === 'All' ? undefined : data.employeeName,
        receiptOption: data.receiptOption ?? undefined,
        freeTimeExtension: data.freeTimeExtensions ?? undefined
      }
    };

    generateReport(selectedStore, payload);
  };

  const { startDate, endDate } = getValues();

  const { isAfterEnd, isBeforeStart } = useDateRangeValidator(
    startDate,
    endDate
  );

  return (
    <>
      <div className={clsx(styles.cardTitle)}>Filter By</div>
      <form id={receiptAuditTrailId} onSubmit={handleSubmit(onSubmit)}>
        <div className={styles.dateRange}>
          <div className={styles.datePicker} data-testid="startDate">
            <Controller
              control={control}
              name={FormFields.startDate}
              defaultValue={null}
              rules={{
                required: 'This field is required',
                validate: isAfterEnd
              }}
              render={({ onChange, value, name }, { invalid }) => (
                <DatePicker
                  required
                  label="Start Date"
                  placeholder="Select a date"
                  name={name}
                  onChange={date => {
                    clearErrors(FormFields.endDate);
                    onChange(date);
                  }}
                  value={value}
                  invalid={invalid}
                  datePickerProps={{
                    minDate: SIX_MONTHS_AGO,
                    disableFuture: true
                  }}
                  errorMessage={errors?.startDate?.message}
                />
              )}
            />
          </div>
          <div
            className={clsx(styles.datePicker, styles.right)}
            data-testid="endDate"
          >
            <Controller
              control={control}
              name={FormFields.endDate}
              defaultValue={null}
              rules={{
                required: 'This field is required',
                validate: isBeforeStart
              }}
              render={({ onChange, value, name }, { invalid }) => (
                <DatePicker
                  required
                  placeholder="Select a date"
                  label="End Date"
                  name={name}
                  onChange={date => {
                    clearErrors(FormFields.startDate);
                    onChange(date);
                  }}
                  value={value}
                  invalid={invalid}
                  datePickerProps={{
                    minDate: SIX_MONTHS_AGO,
                    disableFuture: true
                  }}
                  errorMessage={errors?.endDate?.message}
                />
              )}
            />
          </div>
        </div>
        <div className={styles.selectGroup1}>
          <Controller
            control={control}
            name={FormFields.paymentOrigin}
            render={({ onChange, value, name }) => {
              return (
                <Select
                  name={name}
                  label="Payment Origin"
                  options={makeOptions(PaymentOriginNames)}
                  value={value}
                  size="large"
                  onChange={onChange}
                  className={clsx(styles.datePicker)}
                />
              );
            }}
          />
          <Controller
            control={control}
            name={FormFields.employeeName}
            render={({ onChange, value, name }) => (
              <Select
                name={name}
                label="Employee Name"
                options={employeesOptions}
                onChange={onChange}
                value={value}
                size="large"
                className={clsx(styles.datePicker, styles.employee)}
                disabled={!!employeesApiError}
                errorMessage={employeesApiError}
              />
            )}
          />
        </div>
        <div className={styles.radioGroupText}>
          <span className={styles.text}>
            Only one of the below options can be selected
          </span>
        </div>
        <div className={styles.radioGroup}>
          <Controller
            control={control}
            defaultValue=""
            name={FormFields.radio}
            render={({ onChange, value, name }) => (
              <Radio
                name={name}
                classes={{ label: styles.radioLabel }}
                onChange={e => {
                  const option = e.target.value as OptionalFilterKeys;
                  enableSelect(option);
                  onChange(e);
                }}
                value={value}
                options={makeOptions(OptionalFilterNames)}
                data-testid="radioGroup"
              />
            )}
          />
        </div>
        <div className={styles.selectGroup2}>
          <div
            className={clsx(styles.select)}
            data-testid={FormFields.receiptOption}
          >
            <Controller
              control={control}
              name={FormFields.receiptOption}
              render={({ onChange, value, name }) => (
                <Select
                  disabled={!isEnabled(name)}
                  name={name}
                  label=""
                  value={value}
                  onChange={onChange}
                  placeholder="Select Receipt Option"
                  options={receiptOptions}
                  size="large"
                  className={styles.receipt}
                />
              )}
            />
          </div>
          <div>
            <div className={styles.inBetweenSelects}>
              <span className={styles.text}>Or</span>
            </div>
          </div>
          <div
            className={clsx(styles.select)}
            data-testid={FormFields.freeTimeExtensions}
          >
            <Controller
              control={control}
              name={FormFields.freeTimeExtensions}
              render={({ onChange, value, name }) => (
                <Select
                  disabled={!isEnabled(name)}
                  name={name}
                  label=""
                  onChange={onChange}
                  value={value}
                  placeholder="Select Free Time/Extensions"
                  options={freeTimeExtensionOptions}
                  size="large"
                  className={styles.freeTime}
                />
              )}
            />
          </div>
        </div>
        <Footer>
          <BackButton disabled={pending} />
          <Button
            disabled={pending}
            data-testid="generateReport"
            type="submit"
            form={receiptAuditTrailId}
          >
            Generate Report
          </Button>
        </Footer>
      </form>
    </>
  );
};
