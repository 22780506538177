import { FC } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import {
  FreeTimeExtensionKeys,
  PaymentOriginKeys,
  ReceiptOptionKeys
} from '../../components/reports/ReceiptAuditTrail/ReceiptAuditTrail';
import { CustomerType } from '../../components/reports/OnRentInventory/OnRentInventory';

export enum ReportType {
  REPORT_AUDIT_TRAIL = 'REPORT_AUDIT_TRAIL',
  IDLE_INVENTORY = 'IDLE_INVENTORY',
  ITEMS_BEING_SERVICED = 'ITEMS_BEING_SERVICED',
  TRANSACTION_AUDIT_TRAIL = 'TRANSACTION_AUDIT_TRAIL',
  ON_RENT_INVENTORY = 'ON_RENT_INVENTORY',
  RECEIPT_AUDIT_TRAIL = 'RECEIPT_AUDIT_TRAIL',
  EXCEPTION_AUDIT_TRAIL = 'EXCEPTION_AUDIT_TRAIL',
  SKIP_STOLEN = 'SKIP_STOLEN',
  INVENTORY_IN_AUDIT_TRAIL = 'INVENTORY_IN_AUDIT_TRAIL',
  INVENTORY_OUT_AUDIT_TRAIL = 'INVENTORY_OUT_AUDIT_TRAIL',
  INVENTORY_RECAP = 'INVENTORY_RECAP',
  PETTY_CASH_ANALYSIS = 'PETTY_CASH_ANALYSIS',
  PETTY_CASH_ANALYSIS_SUMMARY = 'PETTY_CASH_ANALYSIS_SUMMARY',
  SALES_PERSON_RECAP = 'SALES_PERSON_RECAP',
  DAILY_REPORT = 'DAILY_REPORT',
  DAILY_ACTIVITY_PLANNER_EVENT = 'DAILY_ACTIVITY_PLANNER_EVENT'
}

export enum GenerateReportType {
  RECEIPT_AUDIT_TRAIL = 'RECEIPT_AUDIT_TRAIL',
  TRANSACTION_AUDIT_TRAIL = 'TRANSACTION_AUDIT_TRAIL',
  DAILY_REPORT = 'DAILY_REPORT',
  INVENTORY_OUT_AUDIT_TRAIL = 'INVENTORY_OUT_AUDIT_TRAIL',
  INVENTORY_IN_AUDIT_TRAIL = 'INVENTORY_IN_AUDIT_TRAIL',
  INVENTORY_STATUS_IDLE = 'INVENTORY_STATUS_IDLE',
  PETTY_CASH_DETAIL = 'PETTY_CASH_DETAIL',
  PETTY_CASH_SUMMARY = 'PETTY_CASH_SUMMARY',
  SKIP_STOLEN_SUMMARY = 'SKIP_STOLEN_SUMMARY',
  REPORT_AUDIT_TRAIL = 'REPORT_AUDIT_TRAIL',
  SALES_PERSON_RECAP = 'SALES_PERSON_RECAP',
  EXCEPTION_AUDIT_TRAIL = 'EXCEPTION_AUDIT_TRAIL',
  INVENTORY_RECAP = 'INVENTORY_RECAP',
  ITEMS_BEING_SERVICED = 'ITEMS_BEING_SERVICED',
  LOANER_REPORT = 'LOANER_REPORT',
  DAILY_ACTIVITY_PLANNER_EVENT = 'DAILY_ACTIVITY_PLANNER_EVENT'
}

export enum LinkAction {
  View = 'view',
  Download = 'download'
}

export interface ReportUrlParams {
  extension: FileType;
  action: LinkAction;
}

export const GenerateReportTypeNames: Record<GenerateReportType, string> = {
  [GenerateReportType.RECEIPT_AUDIT_TRAIL]: 'Receipt Audit Trail',
  [GenerateReportType.TRANSACTION_AUDIT_TRAIL]: 'Transaction Audit Trail',
  [GenerateReportType.DAILY_REPORT]: 'Daily Report',
  [GenerateReportType.INVENTORY_OUT_AUDIT_TRAIL]: 'Inventory Out Audit Trail',
  [GenerateReportType.INVENTORY_IN_AUDIT_TRAIL]: 'Inventory In Audit Trail',
  [GenerateReportType.INVENTORY_STATUS_IDLE]: 'Idle Inventory',
  [GenerateReportType.PETTY_CASH_DETAIL]: 'Petty Cash Analysis Details',
  [GenerateReportType.PETTY_CASH_SUMMARY]: 'Petty Cash Analysis Summary',
  [GenerateReportType.SKIP_STOLEN_SUMMARY]: 'Skip Stolen',
  [GenerateReportType.REPORT_AUDIT_TRAIL]: 'Report Audit Trail',
  [GenerateReportType.SALES_PERSON_RECAP]: 'Sales Person Recap',
  [GenerateReportType.EXCEPTION_AUDIT_TRAIL]: 'Exception Audit Trail',
  [GenerateReportType.INVENTORY_RECAP]: 'Inventory Recap',
  [GenerateReportType.ITEMS_BEING_SERVICED]: 'Items Being Serviced',
  [GenerateReportType.LOANER_REPORT]: 'On Rent Inventory',
  [GenerateReportType.DAILY_ACTIVITY_PLANNER_EVENT]: 'DAP Event Report'
};

export enum SubReportTypeKeys {
  All = 'All',
  DELIVERIES = 'DELIVERIES',
  RETURNS = 'RETURNS',
  TRANSFER_IN_AGR = 'TRANSFER_IN_AGR',
  TRANSFER_IN_INV = 'TRANSFER_IN_INV',
  TRANSFER_OUT_INV = 'TRANSFER_OUT_INV',
  CHARGE_OFF_EPO_PIF = 'CHARGE_OFF_EPO_PIF',
  CHARGE_OFF_AGR = 'CHARGE_OFF_AGR',
  CHARGE_OFF_CASH_SALE = 'CHARGE_OFF_CASH_SALE',
  CHARGE_OFF_INV = 'CHARGE_OFF_INV',
  SWITCHOUTS = 'SWITCHOUTS',
  RECEIVERS = 'RECEIVERS',
  SERVICE_LOANER = 'SERVICE_LOANER',
  PROMO_LOANER = 'PROMO_LOANER',
  ECOM_PRELIM_PAID = 'ECOM_PRELIM_PAID',
  ECOM_PRELIM_INVENTORY = 'ECOM_PRELIM_INVENTORY',
  ECOM_PRELIM_REFUND = 'ECOM_PRELIM_REFUND',
  ECOM_PRELIM_CANCEL = 'ECOM_PRELIM_CANCEL',
  ECOM_ACTIVE = 'ECOM_ACTIVE',
  MANUAL_PO = 'MANUAL_PO'
}

export const SubReportTypeNames: Record<SubReportTypeKeys, string> = {
  [SubReportTypeKeys.All]: 'All',
  [SubReportTypeKeys.DELIVERIES]: 'Deliveries',
  [SubReportTypeKeys.RETURNS]: 'Returns',
  [SubReportTypeKeys.TRANSFER_IN_AGR]: 'TransferInAgreement',
  [SubReportTypeKeys.TRANSFER_IN_INV]: 'TransferInInventory',
  [SubReportTypeKeys.TRANSFER_OUT_INV]: 'TransferOutInventory',
  [SubReportTypeKeys.CHARGE_OFF_EPO_PIF]: 'Charge Off EPO/PIF',
  [SubReportTypeKeys.CHARGE_OFF_AGR]: 'ChargeOffAgreement',
  [SubReportTypeKeys.CHARGE_OFF_CASH_SALE]: 'Charge Off Cash Sale',
  [SubReportTypeKeys.CHARGE_OFF_INV]: 'ChargeOffInventory',
  [SubReportTypeKeys.SWITCHOUTS]: 'Switchouts',
  [SubReportTypeKeys.RECEIVERS]: 'Receivers',
  [SubReportTypeKeys.SERVICE_LOANER]: 'Service Loaner',
  [SubReportTypeKeys.PROMO_LOANER]: 'Promotional Loaner',
  [SubReportTypeKeys.ECOM_PRELIM_PAID]: 'Prelim Paid',
  [SubReportTypeKeys.ECOM_PRELIM_INVENTORY]: 'Prelim Inventory',
  [SubReportTypeKeys.ECOM_PRELIM_REFUND]: 'Prelim Refund',
  [SubReportTypeKeys.ECOM_PRELIM_CANCEL]: 'Prelim Cancel',
  [SubReportTypeKeys.ECOM_ACTIVE]: 'eComm/SPOR Active',
  [SubReportTypeKeys.MANUAL_PO]: 'Manual PO'
};

export interface DateRangeFilter {
  startDate: string;
  endDate: string;
}

export type ReportAuditTrailFilters = DateRangeFilter;

export interface DailyReportFilters {
  businessDate: string;
}

export interface ExceptionAuditTrailFilters extends DateRangeFilter {
  empId: string;
  accountManagement: string;
  payment: string;
  cashManagement: string;
  inventory: string;
  customer: string;
  agreement: string;
}

export interface PettyCashSummaryFilters {
  startDate: string;
}

export type IdleInventoryDaysValue = 'All' | 'SixtyToEightNine' | 'NintyPlus';

export interface IdleInventoryReportFilters {
  idleInventoryDaysFilter: IdleInventoryDaysValue;
}

export interface ReceiptAuditTrailFilters extends DateRangeFilter {
  empId: string;
  paymentOrigin: PaymentOriginKeys;
  employeeName: string;
  receiptOption?: ReceiptOptionKeys;
  freeTimeExtension?: FreeTimeExtensionKeys;
}

export interface PettyCashDetailFilters extends DateRangeFilter {
  empId: string;
}

export type InventoryInAuditTrailFilters = DateRangeFilter;

export interface ReportServerItem {
  displayValue: string;
  menuItemValue: string;
  menuItemKey: ReportType;
}

export interface ReportEntry {
  type: ReportType;
  url: string;
  title: string;
  component: FC<RouteComponentProps>;
}

export interface OnRentInventoryFilters {
  customerType: CustomerType;
  isGrouping?: boolean;
  route?: string;
}

export interface ReportHistory {
  id: string;
  reportType: GenerateReportType;
  lastUpdatedDate: string;
  createdDate: string;
  S3UrlCSV: string;
  S3UrlPDF: string;
  reportPDFStatus: string;
  reportCSVStatus: string;
  reportFilters: ReportFilters;
  storeId: string;
  userId: string;
}

export enum ReportStatus {
  InProgress = 'InProgress',
  Completed = 'Completed'
}

export enum FileType {
  PDF = 'PDF',
  CSV = 'CSV'
}

export type SalesPersonRecapFilters = DateRangeFilter;

export type SkipStolenFilters = DateRangeFilter;

export type InventoryOutAuditTrailFilters = DateRangeFilter;

export interface TransactionAuditTrailFilters extends DateRangeFilter {
  empId: string;
  subReportType?: SubReportTypeKeys;
}

export interface DAPEventReportFilters extends DateRangeFilter {
  coWorkers: string[];
  events: string[];
  sources: string[];
  statuses: string[];
}

export type ReportFilters =
  | ReportAuditTrailFilters
  | DailyReportFilters
  | InventoryInAuditTrailFilters
  | IdleInventoryReportFilters
  | OnRentInventoryFilters
  | ReceiptAuditTrailFilters
  | SalesPersonRecapFilters
  | ExceptionAuditTrailFilters
  | SkipStolenFilters
  | InventoryOutAuditTrailFilters
  | PettyCashSummaryFilters
  | PettyCashDetailFilters
  | TransactionAuditTrailFilters
  | DAPEventReportFilters;
