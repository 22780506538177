import React, { useContext } from 'react';
import { Tabs } from '@rentacenter/racstrap';
import {
  matchPath,
  Redirect,
  Route,
  Switch,
  useHistory,
  useLocation
} from 'react-router-dom';

import styles from './Main.module.scss';

import { StoreContext } from '../../../context/store/StoreProvider';
import { ReportsRoute } from '../../../config/route-config';
import { ReportsList } from '../../ReportsList/ReportsList';
import { ReportsHistory } from '../../ReportsHistory/ReportsHistory';
import { NoStoreSelected } from '../NoStoreSelected/NoStoreSelected';
import { StoreSelector } from '../../StoreSelector/StoreSelector';

export const Main = () => {
  const history = useHistory();
  const location = useLocation();
  const { selectedStore } = useContext(StoreContext);

  const handleTabsChange = (event: any, tabIndex: any) => {
    switch (tabIndex) {
      case 0:
        history.push(ReportsRoute.Index);
        break;
      case 1:
        history.push(ReportsRoute.REPORT_HISTORY);
        break;
    }
  };

  const isHistoryRoute = matchPath(location.pathname, {
    path: ReportsRoute.REPORT_HISTORY
  });

  const currentTab = isHistoryRoute ? 1 : 0;

  return (
    <div className={styles.mainWrapper}>
      <StoreSelector />
      <Tabs
        value={currentTab}
        classes={{ tabs: styles.tabs }}
        onChange={handleTabsChange}
      >
        <div title="Report Types"></div>
        <div title="Reports History"></div>
      </Tabs>

      {selectedStore ? (
        <Switch>
          <Route exact path={ReportsRoute.Index}>
            <ReportsList />
          </Route>

          <Route exact path={ReportsRoute.REPORT_HISTORY}>
            <ReportsHistory />
          </Route>

          <Route>
            <Redirect to={ReportsRoute.Index} />
          </Route>
        </Switch>
      ) : (
        <NoStoreSelected />
      )}
    </div>
  );
};
