import axios, { CancelTokenSource } from 'axios';
import { Auth } from '@aws-amplify/auth';

import { appConfig } from '../config/app-config';

async function getJWToken() {
  try {
    const currentSession = await Auth.currentSession();

    return currentSession.getIdToken().getJwtToken();
  } catch (error) {
    throw new Error(`An error occurred: ${error}.`);
  }
}

export async function client(
  endpoint: string,
  { requestType, body, ...customConfig }: any = {},
  isApiFromContainer = false
) {
  const jwtToken = await getJWToken();

  const headers: any = {
    'Content-Type': 'application/json; charset=UTF-8'
  };

  if (jwtToken) {
    headers.Authorization = `Bearer ${jwtToken}`;
  }

  const requestConfig: any = {
    method: requestType,
    ...customConfig,
    headers: {
      ...headers,
      ...customConfig.headers
    }
  };

  if (body) {
    requestConfig.data = JSON.stringify(body);
  }

  const url = `${
    isApiFromContainer ? appConfig.containerApiUrl : appConfig.microApiUrl
  }/${endpoint}`;

  return axios(url, requestConfig).then(response => response.data);
}

export const getCancelTokenSource = (): CancelTokenSource =>
  axios.CancelToken.source();
