import { ReportEntry, ReportType } from './Reports';
import { DailyReport } from '../../components/reports/DailyReport/DailyReport';
import { OnRentInventory } from '../../components/reports/OnRentInventory/OnRentInventory';
import { TransactionAuditTrail } from '../../components/reports/TransactionAuditTrailReport/TransactionAuditTrail';
import { ReportAuditTrail } from '../../components/reports/ReportAuditTrailReport/ReportAuditTrail';
import { SkipStolenReport } from '../../components/reports/SkipStolenReport/SkipStolen';
import { InventoryInAuditTrail } from '../../components/reports/InventoryInAuditTrail/InventoryInAuditTrial';
import { PettyCashDetail } from '../../components/reports/PettyCashDetailAnalysis/PettyCashDetail';
import { ExceptionAuditTrail } from '../../components/reports/ExceptionAuditTrailReport/ExceptionAuditTrail';
import { ReportsRoute } from '../../config/route-config';
import { ReceiptAuditTrail } from '../../components/reports/ReceiptAuditTrail/ReceiptAuditTrail';
import { IdleInventoryReport } from '../../components/reports/IdleInventoryReport/IdleInventoryReport';
import { SalesPersonRecapDetails } from '../../components/reports/SalesPersonRecapDetailsReport/SalesPersonRecapDetails';
import { PettyCashSummary } from '../../components/reports/PettyCashSummaryReport/PettyCashSummary';
import { InventoryOutAuditTrail } from '../../components/reports/InventoryOutAuditTrailReport/InventoryOutAuditTrail';
import { ItemsBeingServiced } from '../../components/reports/ItemsBeingServicedReport/ItemsBeingServiced';
import { InventoryRecap } from '../../components/reports/InventoryRecapReport/InventoryRecap';
import { DAPEventReport } from '../../components/reports/DAPEventReport/DAPEventReport';

export const availableReports: ReportEntry[] = [
  {
    type: ReportType.DAILY_REPORT,
    url: ReportsRoute.DAILY_REPORT,
    component: DailyReport,
    title: 'Daily Report'
  },
  {
    type: ReportType.ON_RENT_INVENTORY,
    url: ReportsRoute.ON_RENT_INVENTORY,
    component: OnRentInventory,
    title: 'On Rent Inventory'
  },
  {
    type: ReportType.TRANSACTION_AUDIT_TRAIL,
    url: ReportsRoute.TRANSACTION_AUDIT_TRAIL_REPORT,
    component: TransactionAuditTrail,
    title: 'Transaction Audit Trail Report'
  },
  {
    type: ReportType.INVENTORY_IN_AUDIT_TRAIL,
    url: ReportsRoute.INVENTORY_IN_AUDIT_TRAIL,
    component: InventoryInAuditTrail,
    title: 'Inventory In Audit Trail Report'
  },
  {
    type: ReportType.PETTY_CASH_ANALYSIS,
    url: ReportsRoute.PETTY_CASH_DETAIL_ANALYSIS,
    component: PettyCashDetail,
    title: 'Petty Cash Detail Analysis Report'
  },
  {
    type: ReportType.REPORT_AUDIT_TRAIL,
    url: ReportsRoute.REPORT_AUDIT_TRAIL_REPORT,
    component: ReportAuditTrail,
    title: 'Report Audit Trail'
  },
  {
    type: ReportType.RECEIPT_AUDIT_TRAIL,
    url: ReportsRoute.RECEIPT_AUDIT_TRAIL,
    component: ReceiptAuditTrail,
    title: 'Receipt Audit Trail Report'
  },
  {
    type: ReportType.SKIP_STOLEN,
    url: ReportsRoute.SKIP_STOLEN_REPORT,
    component: SkipStolenReport,
    title: 'Skip Stolen Report'
  },
  {
    type: ReportType.IDLE_INVENTORY,
    url: ReportsRoute.IDLE_INVENTORY,
    component: IdleInventoryReport,
    title: 'Idle Inventory Report'
  },
  {
    type: ReportType.SALES_PERSON_RECAP,
    url: ReportsRoute.SALES_PERSON_RECAP_DETAILS,
    component: SalesPersonRecapDetails,
    title: 'Sales Person Recap Details Report'
  },
  {
    type: ReportType.EXCEPTION_AUDIT_TRAIL,
    url: ReportsRoute.EXCEPTION_AUDIT_TRAIL,
    component: ExceptionAuditTrail,
    title: 'Exception Audit Trail Report'
  },
  {
    type: ReportType.INVENTORY_OUT_AUDIT_TRAIL,
    url: ReportsRoute.INVENTORY_OUT_AUDIT_TRAIL,
    component: InventoryOutAuditTrail,
    title: 'Inventory Out Audit Trail Report'
  },
  {
    type: ReportType.PETTY_CASH_ANALYSIS_SUMMARY,
    url: ReportsRoute.PETTY_CASH_ANALYSIS_SUMMARY,
    component: PettyCashSummary,
    title: 'Petty Cash Analysis Summary Report'
  },
  {
    type: ReportType.ITEMS_BEING_SERVICED,
    url: ReportsRoute.ITEMS_BEING_SERVICED,
    component: ItemsBeingServiced,
    title: 'Items Being Serviced Report'
  },
  {
    type: ReportType.INVENTORY_RECAP,
    url: ReportsRoute.INVENTORY_RECAP,
    component: InventoryRecap,
    title: 'Inventory Recap Report'
  },
  {
    type: ReportType.DAILY_ACTIVITY_PLANNER_EVENT,
    url: ReportsRoute.DAILY_ACTIVITY_PLANNER_EVENT,
    component: DAPEventReport,
    title: 'Daily Activity Planner Events Report'
  }
];

export function getReportURLForType(reportType: ReportType) {
  const foundEntry = availableReports.find(report => {
    return report.type === reportType.trim();
  });

  return foundEntry?.url;
}
