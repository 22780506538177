import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  Button,
  Label,
  Radio,
  Select,
  SelectOption
} from '@rentacenter/racstrap';

import styles from './OnRentInventory.module.scss';

import { getStoreRoutes } from '../../../api/api';
import { StoreContext } from '../../../context/store/StoreProvider';
import { Footer } from '../../layout/footer/Footer';
import { BackButton } from '../BackButton';
import { GenericObject } from '../../../utils/GenericObject';
import { GenerateReportType } from '../../../domain/Reports/Reports';
import { useGenerateReport } from '../useGenerateReport';

export enum CustomerType {
  OR = 'OR',
  OL = 'OL'
}

export const CustomerTypeNames: Record<CustomerType, string> = {
  [CustomerType.OL]: 'Only Loaners/Promos',
  [CustomerType.OR]: 'Customers on Rent'
};

enum RouteType {
  NoRouteGrouping = 'NoRouteGrouping',
  AllRoutesGrouping = 'AllRoutesGrouping',
  SelectedRoute = 'SelectedRoute'
}

interface OnRentInventoryForm {
  readonly customerType: CustomerType;
  readonly route: RouteType | '';
  readonly selectedRoute: number | '';
}

function buildFilters({
  customerType,
  route,
  selectedRoute
}: OnRentInventoryForm): GenericObject {
  return {
    customerType,
    ...(route === RouteType.SelectedRoute
      ? {
          route: selectedRoute
        }
      : { isGrouping: route === RouteType.AllRoutesGrouping })
  };
}

export const filterUnavailable = 'Filter unavailable';
export const OnRentInventory = () => {
  const { selectedStore } = useContext(StoreContext);
  const { pending, generateReport } = useGenerateReport();

  const [routesOptions, setRoutesOptions] = useState<SelectOption[]>();
  const [routesApiError, setRoutesApiError] = useState<string>();

  const { control, handleSubmit, setValue } = useForm<OnRentInventoryForm>({
    mode: 'all'
  });

  const routeValue = useWatch({ control, name: 'route' });

  const onSubmit = (data: OnRentInventoryForm) => {
    if (!selectedStore) return;

    generateReport(selectedStore, {
      reportType: GenerateReportType.LOANER_REPORT,
      filters: buildFilters(data)
    });
  };

  useEffect(
    function fetchRoutes() {
      if (!selectedStore) return;
      getStoreRoutes(selectedStore)
        .then(data => {
          if (data?.length > 0) {
            setRoutesOptions(
              data.map((item: GenericObject) => {
                const { routeDescription: label, routeCode: value } = item;
                return { label, value };
              })
            );
          } else {
            setRoutesApiError(filterUnavailable);
          }
        })
        .catch(() => {
          setRoutesApiError(filterUnavailable);
        });
    },
    [selectedStore, setRoutesApiError]
  );

  useEffect(
    function setRoutesSelect() {
      routeValue === RouteType.SelectedRoute && routesOptions
        ? setValue('selectedRoute', routesOptions[0].value)
        : setValue('selectedRoute', '');
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [routeValue, routesOptions]
  );

  return (
    <form onSubmit={handleSubmit(onSubmit)} aria-label="form">
      <div className={styles.formTitle}>Filter By</div>
      <div className={styles.formGroup}>
        <Label className={styles.label} required forInput="customerType">
          Customers
        </Label>
        <Controller
          control={control}
          name="customerType"
          defaultValue={CustomerType.OR}
          render={({ onBlur, ref, ...rest }) => (
            <Radio
              options={[
                { label: 'Customers on Rent', value: CustomerType.OR },
                { label: 'Only Loaners/Promos', value: CustomerType.OL }
              ]}
              {...rest}
            />
          )}
        />
      </div>
      <div className={styles.formGroup}>
        <Label className={styles.label} forInput="route">
          Routes
        </Label>
        <div className={styles.row}>
          <Controller
            control={control}
            name="route"
            defaultValue=""
            render={({ onBlur, ref, ...rest }) => (
              <Radio
                classes={{ label: styles.radioItemLabel }}
                options={[
                  {
                    label: 'No Route Grouping',
                    value: RouteType.NoRouteGrouping
                  },
                  {
                    label: 'All Routes Grouping',
                    value: RouteType.AllRoutesGrouping
                  },
                  ...(!routesApiError
                    ? [
                        {
                          label: 'Selected Route',
                          value: RouteType.SelectedRoute
                        }
                      ]
                    : [])
                ]}
                {...rest}
              />
            )}
          />
          <Controller
            control={control}
            name="selectedRoute"
            defaultValue=""
            render={({ onBlur, ref, ...rest }) => {
              return (
                <Select
                  size="large"
                  placeholder="Select a Route"
                  classes={{ select: styles.routesSelect }}
                  options={routesOptions}
                  disabled={
                    routeValue !== RouteType.SelectedRoute || !!routesApiError
                  }
                  errorMessage={routesApiError}
                  {...rest}
                />
              );
            }}
          />
        </div>
      </div>

      <Footer>
        <BackButton disabled={pending} />
        <Button type="submit" disabled={pending}>
          Generate Report
        </Button>
      </Footer>
    </form>
  );
};
