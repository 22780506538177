import React, { useContext, useState } from 'react';
import { Button, Radio, SelectOption } from '@rentacenter/racstrap';
import { useForm, Controller } from 'react-hook-form';
import styles from './IdleInventoryReport.module.scss';

import { StoreContext } from '../../../context/store/StoreProvider';
import { Footer } from '../../layout/footer/Footer';
import { BackButton } from '../BackButton';
import { GenerateReportType } from '../../../domain/Reports/Reports';
import { useGenerateReport } from '../useGenerateReport';

interface IdleInventoryReport {
  readonly idleInventoryDays?: string;
}

const reportIdleInventoryFormId = 'reportIdleInventoryFormId';

export const IdleInventoryReport = () => {
  const { selectedStore } = useContext(StoreContext);
  const { pending, generateReport } = useGenerateReport();

  const [selectedIdleInventory, setSelectedIdleInventory] = useState<string>(
    'All'
  );
  const idleInventoryDays: SelectOption[] = [
    { label: 'All', value: 'All' },
    { label: '60-89', value: 'SixtyToEightNine' },
    { label: '90+', value: 'NintyPlus' }
  ];

  const { control, handleSubmit } = useForm<IdleInventoryReport>({
    mode: 'all',
    defaultValues: {
      idleInventoryDays: ''
    }
  });

  const onSubmit = (data: IdleInventoryReport) => {
    if (!selectedStore) return;

    generateReport(selectedStore, {
      reportType: GenerateReportType.INVENTORY_STATUS_IDLE,
      filters: {
        idleInventoryDaysFilter: data.idleInventoryDays || selectedIdleInventory
      }
    });
  };

  return (
    <form id={reportIdleInventoryFormId} onSubmit={handleSubmit(onSubmit)}>
      <div className={styles.formTitle}>Filter By</div>
      <Controller
        control={control}
        name="idleInventoryDays"
        render={({ onChange, name }) => (
          <Radio
            name={name}
            value={selectedIdleInventory}
            onChange={e => {
              setSelectedIdleInventory(e.target.value);
              onChange(e);
            }}
            options={idleInventoryDays}
          />
        )}
      />
      <Footer>
        <BackButton disabled={pending} />
        <Button type="submit" disabled={pending}>
          Generate Report
        </Button>
      </Footer>
    </form>
  );
};
